import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ChatBot from "../components/ChatBot";
import LoadingScreen from "../components/LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const RefundPolicyPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 200);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section
        id="refund"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-28 sm:pb-20 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 mb-6 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white bg-opacity-40 p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-lg font-semibold tracking-widest text-stone-800 sm:text-3xl">
              Refund Policy
            </h1>
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:py-6 sm:text-[20px] lg:px-24">
            <div>
              <h1 className="font-medium uppercase underline">Overview</h1>
              <p className="my-3 sm:my-5">
                At One Life Health Care, we are dedicated to providing a
                supportive and valuable community platform that primarily offers
                free services to our users. Additionally, we provide an option
                for users to consult with professional therapists for a fee. Our
                refund policy is designed to ensure clarity and fairness for all
                parties involved.
              </p>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                General Policy
              </h1>
              <p className="my-3 sm:my-5">
                Our standard policy is that all payments made for therapist
                consultations are non-refundable. We believe in the value and
                quality of our services and the commitment of our professional
                therapists. Therefore, we do not offer refunds under normal
                circumstances.
              </p>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Exception to the No Refund Policy
              </h1>
              <p className="my-3 sm:my-5">
                We understand that exceptional circumstances may arise where the
                services promised were not delivered as agreed. In such cases, a
                refund may be considered under the following conditions:
              </p>
              <ul className="list-decimal">
                <p className="my-2">
                  <span className="font-medium">1. Service Not Delivered:</span>{" "}
                  If the therapy session or consultation you paid for was not
                  provided as promised, you may be eligible for a refund.
                </p>
                <p className="my-2">
                  <span className="font-medium">2. Notification Period:</span>{" "}
                  To request a refund under these exceptional circumstances, you
                  must notify us within 24 hours of the scheduled consultation
                  or service time.
                </p>
                <p className="my-2">
                  <span className="font-medium">3. Confirmation Process:</span>{" "}
                  Once your refund request is received, we will initiate a
                  confirmation process. Both the client and the company must
                  confirm that the service was not delivered as promised. This
                  process includes:
                </p>
                <p className="my-2">
                  <span className="">- Client Confirmation:</span> You will need
                  to provide a detailed explanation and any relevant evidence
                  (such as communication records) to support your claim that the
                  service was not delivered.
                </p>
                <p className="my-2">
                  <span className="">- Company Review:</span> Our team will
                  review the provided information and verify with the therapist
                  or service provider regarding the missed consultation.
                </p>{" "}
                <p className="my-2">
                  <span className="font-medium">4. Resolution Timeframe:</span>{" "}
                  Upon confirmation from both the client and the company, we aim
                  to process the refund within 5-7 business days. You will
                  receive a notification once the refund has been processed.
                </p>
              </ul>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                How to Request a Refund
              </h1>
              <ul className="list-decimal">
                <p className="my-2">
                  <span className="font-medium">1. Contact Us:</span> Reach out
                  to our customer support team at [support email] within 24
                  hours of the missed service.
                </p>
                <p className="my-2">
                  <span className="font-medium">2. Provide Details:</span>{" "}
                  Include your full name, contact information, the date and time
                  of the missed consultation, and a detailed explanation of the
                  issue.
                </p>
                <p className="my-2">
                  <span className="font-medium">3. Await Confirmation:</span>{" "}
                  Our team will review your request and contact you for further
                  information if needed.
                </p>
              </ul>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Important Notes
              </h1>
              <p className="my-2">
                - Refund requests made after the 24-hour notification period
                will not be considered.
              </p>
              <p className="my-2">
                - Refunds will only be processed if both the client and the
                company confirm that the service was not delivered as promised.
              </p>
              <p className="my-2">
                - The refund will be credited back to the original payment
                method used during the transaction.
              </p>
              <p className="my-2">
                We appreciate your understanding and cooperation in adhering to
                this policy. Our goal is to ensure a fair and transparent
                process for all users while maintaining the quality and
                reliability of our services.
              </p>

              <p className="my-2">
                For any questions or concerns regarding our refund policy,
                please contact us at{" "}
                <span className="font-medium">support@onelifehealth.care</span>
              </p>
              <p className="my-2">
                Thank you for choosing One Life Health Care.
              </p>
              <p className="mb-1 mt-4 font-medium">Sincerely,</p>
              <p className="font-semibold">The One Life Health Care Team</p>
            </div>
          </div>
        </div>
      </section>
      <ChatBot />
      <Footer />
    </>
  );
};

export default RefundPolicyPage;
