import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface Post {
  _id: string;
  title: string;
  body: string;
  userId: string;
  likes: number;
  views: number;
  commentCount: number;
  status: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  liked: boolean;
}

export interface PostState {
  posts: Post[];
  loading: boolean;
  error: string | null;
  page: number;
}

const initialState: PostState = {
  posts: [],
  loading: false,
  error: null,
  page: 1,
};

export const fetchPosts = createAsyncThunk(
  "posts/fetchPosts",
  async ({
    sort,
    limit,
    page,
  }: {
    sort: string;
    limit: number;
    page: number;
  }) => {
    const response = await axios.get(
      `${REACT_APP_API_URL}/post/all?sort=${sort}&limit=${limit}&page=${page}`,
    );
    return response.data.data;
  },
);

export const likePost = createAsyncThunk(
  "posts/likePost",
  async (postId: string, { getState }) => {
    const state = getState() as { auth: { token: string | null } };
    const token = state.auth.token;
    if (!token) throw new Error("Please login first");

    const response = await axios.post(
      `${REACT_APP_API_URL}/post/like/${postId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    const { liked, likes } = response.data.data;

    return { postId, liked, likes };
  },
);

const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPosts.fulfilled, (state, action: PayloadAction<Post[]>) => {
        state.loading = false;
        state.posts = [...state.posts, ...action.payload];
        state.page += 1;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch posts";
      })
      .addCase(likePost.fulfilled, (state, action) => {
        const { postId, liked, likes } = action.payload;
        const post = state.posts.find((post) => post._id === postId);
        if (post) {
          post.liked = liked;
          post.likes = likes;
        }
      })
      .addCase(likePost.rejected, (state, action) => {
        state.error = action.error.message || "Failed to like the post";
      });
  },
});

export default postSlice.reducer;
