import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const { REACT_APP_S3_URL } = process.env;

const SleepDisorders = () => {
  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-70 px-8 pb-6 pt-24 font-heading lg:px-24 lg:pb-20 lg:pt-36"
      >
        <img
          src={`${REACT_APP_S3_URL}/assets/sleep-disorder.jpg`}
          alt="sleep-disorder"
          className="w-full"
        />
        <h1 className="mb-4 mt-8 text-left text-2xl font-medium leading-snug sm:text-3xl lg:text-5xl lg:leading-tight">
          How Does Sleep Disorders Impact the Mental Health of a Person?
        </h1>
        <p className="font-bold sm:text-xl">By Gopinath Srinivasa</p>
        <p className="font-medium sm:text-lg">February 21, 2022</p>
        <div className="my-5 text-lg lg:my-10 lg:text-xl">
          <p>
            Sleep-related issues are one of the most commonly seen issues in
            young populations including adolescents, adults & some children as
            well, the sleep-related problems in the young population are seen
            due to various reasons such as Biological, Psychological &
            Sociological factors. The sleep-related problems are seen more in
            recent times; the covid-19 pandemic has made a great impact on our
            lifestyles and our dependency on electronic gadgets have spiked up
            in frequency and the screen time has increased due to binge-watching
            digital content such as TV shows, web series, movies, scrolling
            through Instagram & other social media apps are among the commonly
            seen issues in the young population. The sleep-related problems, if
            not addressed appropriately can lead to more severe issues & an
            individual may develop Sleep Disorders and it can affect one’s
            Mental health. Often sleep deprivation in an individual would result
            in developing mental health problems such as Depression, Anxiety,
            Mood & Anger Management issues, etc.
          </p>
          <p className="my-4">
            Before discussing further Sleep Disorders, let us understand what is
            Normal Sleep or Good sleep, what are factors indicates good quality
            of sleep in an individual. The quality of sleep is understood to be
            fine when an individual falls asleep quite easily, does not fully
            wake up during the night, does not wake up too early, and feels
            refreshed in the morning. Not everyone requires the same amount of
            sleep and the quality of sleep differs from one person to another.
            Young children and older people sleep more lightly compared to
            adults and teenagers. Babies and young children are required to
            sleep more compared to adolescents & adults.
          </p>
          <p className="my-4">
            The quality of sleep can be defined as “How well one is sleeping,
            i.e. whether one’s sleep is restful and restorative. Sleep quality
            is more complicated to measure than the quantity of sleep, but it’s
            not entirely subjective. The following are the indicators of quality
            of sleep
          </p>
          <ul className="list-disc">
            <li className="mb-2 ml-4">
              <span className="font-medium">Sleep latency:</span> This is a
              measurement of how long it takes you to fall asleep. Drifting off
              within 30 minutes or less after the time you go to bed suggests
              that the quality of your sleep is good.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Sleep waking:</span> This measures
              how often you wake up during the night. Frequent wakefulness at
              night can disrupt your sleep cycle and reduce your sleep quality.
              Waking up once or not at all suggests that your sleep quality is
              good.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Wakefulness</span> This measurement
              refers to how many minutes you spend awake during the night after
              you first go to sleep. People with good sleep quality have 20
              minutes or less of wakefulness during the night.
            </li>
            <li className="ml-4">
              <span className="font-medium">Sleep efficiency:</span> The amount
              of time you spend sleeping while in bed is known as sleep
              efficiency. This measurement should ideally be 85 percent or more
              for optimal health benefits.
            </li>
          </ul>
          <p className="my-4">
            Sleep Disorder is a disruptive pattern of sleep in an individual
            which affects one’s physical, mental, emotional, and social
            functioning. The individual may develop a sleep disorder due to
            various causes such as Biological, Psychological, and Sociological
            factors. Medical conditions such as Traumatic Brain Injury,
            Degenerative Diseases, Mental health issues such as Depression,
            Anxiety, Schizophrenia, Bipolar disorders and cognitive disorders,
            sleep-related problems, and in some cases an individual may even
            develop a sleep disorder. An individual who is going through chronic
            sleep-related problems, a sleep disorder may have difficulties in
            their day-to-day functioning, which would further lead to stress and
            the individual may develop mental health conditions such as
            Depression, Anxiety, Anger management issue, occupational stress,
            etc. Environmental changes such as migrating to a new country or a
            place, changes in one’s lifestyle may lead to the onset of
            sleep-related problems.
          </p>
          <p className="my-4">
            There are several types of sleep disorders and the most commonly
            seen in the young population are discussed below:
          </p>
          <ul className="list-decimal">
            <li className="ml-4">
              <span className="font-medium">Insomnia:</span> The amount is being
              unable to fall asleep or stay asleep for the required amount of
              time. The individual may be seen struggling to attain sleep and
              may wake up much early than their usual timings.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Narcolepsy & Hypersomnia:</span>{" "}
              Extreme daytime sleepiness and inability to stay awake during the
              daytime, an individual will have difficulty in coping with their
              day-to-day functioning and managing their responsibilities.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Sleep Apnea:</span> An individual
              having difficulty in breathing during sleep, usually the breathing
              pattern is inappropriate, the person will stop breathing for 10 or
              more seconds, this is accompanied by snoring. The causal factors
              are biological.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Restless leg syndrome:</span>{" "}
              rresistible urge to move legs, an individual experiences tingling
              or prickly sensation in the legs which further leads to powerful
              leg movement.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Circadian Rhythm disorder:</span> an
              individual having problems in their sleep-wake cycles, unable to
              sleep and wake at right times.
            </li>
            <li className="mb-2 ml-4">
              <span className="font-medium">Parasomnias:</span> the individual
              having unusual or showcasing abnormal behaviors, emotions,
              movements when they are asleep, examples include sleepwalking,
              sleep terror, sleep talking.
            </li>
          </ul>
          <p className="my-4">
            Sleep disorders are seen in young children as well; these issues
            most commonly originate due to underlying psychological stress
            factors, some of them are discussed below:
          </p>
          <ul className="list-decimal">
            <li className="ml-4">
              <span className="font-medium">Enuresis:</span> Also called
              Bedwetting, the child urinating during their sleep, this is not
              caused due to any physiological issues in their bladder control.
            </li>
            <li className="my-2 ml-4">
              <span className="font-medium">Bruxism:</span> also known as Teeth
              grinding, the child is seen biting their teeth or grinding them
              during sleep.
            </li>
          </ul>
          <p className="my-4">
            Many of the above-discussed sleep disorders stem from underlying
            psychological stress factors such as trauma in childhood, anxiety
            issues, difficulty in adapting to changes, occupational stress,
            changes in the environment, or lifestyle changes. The problems are
            likely to get more severe if they are not addressed at the right
            time, the issues may impact one’s mental health and it can cause
            developing issues like anxiety, loneliness, poor attention &
            concentration, memory impairments, mood fluctuations, anger
            management issues, and depression.
          </p>
          <p className="my-4">
            Young adults such as students, employees, are vulnerable to
            developing sleep disorders due to the changes in their lifestyle,
            demanding work culture and dependency on the substances like
            alcohol, marijuana and other drugs impacts their quality of sleep
            which may result in developing disruptive sleep patterns. As mental
            health professionals many Psychologists, Therapists, and Counselors
            come across clients having issues with their quality of sleep and
            sleep-wake cycles and not many of them will have or prone to be
            having underlying Biological or Medical causes, most of these issues
            are developed to inappropriate sleep hygiene, stress due to work,
            interpersonal problems or use of substances. Some of our clients may
            find it difficult to change their lifestyle and may become
            vulnerable to developing more severe issues, in such cases the
            individual requires pharmaceutical interventions from the
            Psychiatrist to cope with the sleep disorders as well as regular
            therapy sessions with their Therapists or Psychologists.
          </p>
          <p className="my-4">
            Making simple changes in our day to day life such as exercising for
            an hour, going walks regularly, eating healthy, consuming less
            caffeine, alcohol, reducing smoking, organizing our bedroom well,
            reducing screen time would help us attain good sleep and it can make
            a positive impact on our quality of sleep.
          </p>
          <p className="my-4">
            There may be many individuals who have been trying to get well or
            cope with their sleep-related problems by following some of the
            measures discussed earlier and still not feeling relived from their
            issues, must consider consulting the professionals like
            Psychiatrist, Psychologists, Therapists, and Counselors for
            treatment and in case of delay in seeking help may result in other
            consequences.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SleepDisorders;
