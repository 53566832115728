import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface Comment {
  _id: string;
  postId: string;
  comment: string;
  userId: string;
  username: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Post {
  _id: string;
  title: string;
  body: string;
  userId: string;
  likes: number;
  views: number;
  commentCount: number;
  status: string;
  tags: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  comments: Comment[];
  liked: boolean;
  bookmarked: boolean;
}

export interface PostDetailState {
  post: Post | null;
  loading: boolean;
  error: string | null;
}

const initialState: PostDetailState = {
  post: null,
  loading: false,
  error: null,
};

export const fetchPostDetail = createAsyncThunk(
  "postDetail/fetchPostDetail",
  async (postId: string, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("XM0CZv3HA9");
      const response = await axios.get(`${REACT_APP_API_URL}/post/${postId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.success) {
        return response.data.data;
      } else {
        return rejectWithValue("Failed to fetch post data");
      }
    } catch (error) {
      return rejectWithValue("Failed to fetch post");
    }
  },
);

export const addComment = createAsyncThunk(
  "postDetail/addComment",
  async (
    { postId, comment }: { postId: string; comment: string },
    { rejectWithValue },
  ) => {
    try {
      const token = localStorage.getItem("XM0CZv3HA9");
      const response = await axios.post(
        `${REACT_APP_API_URL}/post/${postId}/comment`,
        { comment },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to add comment");
    }
  },
);

export const likePost = createAsyncThunk(
  "postDetail/likePost",
  async (postId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as { auth: { token: string | null } };
      const token = state.auth.token;
      if (!token) throw new Error("Please login first");

      const response = await axios.post(
        `${REACT_APP_API_URL}/post/like/${postId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return {
        liked: response.data.data.liked,
        likes: response.data.data.likes,
      };
    } catch (error) {
      return rejectWithValue("Failed to like the post");
    }
  },
);

export const bookmarkPost = createAsyncThunk(
  "postDetail/bookmarkPost",
  async (postId: string, { getState, rejectWithValue }) => {
    try {
      const state = getState() as { auth: { token: string | null } };
      const token = state.auth.token;
      if (!token) throw new Error("Please login first");

      const response = await axios.post(
        `${REACT_APP_API_URL}/post/bookmark/${postId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      return { bookmarked: response.data.data };
    } catch (error) {
      return rejectWithValue("Failed to bookmark the post");
    }
  },
);

const postDetailSlice = createSlice({
  name: "postDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPostDetail.fulfilled,
        (state, action: PayloadAction<Post>) => {
          state.post = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        fetchPostDetail.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(
        addComment.fulfilled,
        (state, action: PayloadAction<Comment>) => {
          if (state.post) {
            state.post.comments.push(action.payload);
          }
        },
      )
      .addCase(likePost.fulfilled, (state, action) => {
        if (state.post) {
          state.post.liked = action.payload.liked;
          state.post.likes = action.payload.likes;
        }
      })
      .addCase(bookmarkPost.fulfilled, (state, action) => {
        if (state.post) {
          state.post.bookmarked = action.payload.bookmarked;
        }
      });
  },
});

export default postDetailSlice.reducer;
