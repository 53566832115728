import { useParams } from "react-router-dom";
import blogPosts from "../data/blogPostsData.json";
import DepressionAndIsolation from "../posts/DepressionAndIsolation";
import BuildingSelfConfidence from "../posts/BuildingSelfConfidence";
import NutritionImpact from "../posts/NurtitionImpact";
import MindfullnessForStress from "../posts/MindfullnessForStress";
import MentalHealthWorkspace from "../posts/MentalHealthWorkspace";
import BeingSensitive from "../posts/BeingSensitive";
import SleepDisorders from "../posts/SleepDisorders";
import NegativeThinking from "../posts/NegativeThinking";
import RegularHealthCheckup from "../posts/RegularHealthCheckup";
import PerfectFamilyMyth from "../posts/PerfectFamilyMyth";
import SocialMedia from "../posts/SocialMedia";
import NoShame from "../posts/NoShame";
import { generateSlug } from "../utils";

const BlogContentPage = () => {
  const { encodedTitle } = useParams();

  const decodedTitle = decodeURIComponent(encodedTitle || "");

  const blogPost = blogPosts.find(
    (post) => generateSlug(post.title) === decodedTitle,
  );

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  switch (blogPost.id) {
    case 1:
      return <DepressionAndIsolation />;
    case 2:
      return <BuildingSelfConfidence />;
    case 3:
      return <NutritionImpact />;
    case 4:
      return <MindfullnessForStress />;
    case 5:
      return <MentalHealthWorkspace />;
    case 6:
      return <SocialMedia />;
    case 7:
      return <RegularHealthCheckup />;
    case 8:
      return <PerfectFamilyMyth />;
    case 9:
      return <NoShame />;
    case 10:
      return <BeingSensitive />;
    case 11:
      return <SleepDisorders />;
    case 12:
      return <NegativeThinking />;
    default:
      return <div>Blog not found</div>;
  }
};

export default BlogContentPage;
