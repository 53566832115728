import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface IProfile {
  _id: string;
  username: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface AuthState {
  token: string | null;
  loading: boolean;
  error: string | null;
  username: string | null;
  profile: IProfile | null;
}

const initialState: AuthState = {
  token: null,
  loading: false,
  error: null,
  username: null,
  profile: null,
};

export const validateToken = createAsyncThunk(
  "auth/validateToken",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as { auth: AuthState };
    const token = state.auth.token;
    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(`${REACT_APP_API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200 && response.data) {
        return {
          username: response.data.username,
          email: response.data.email,
        };
      } else {
        return rejectWithValue("Invalid token");
      }
    } catch (error) {
      return rejectWithValue("Invalid token");
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setProfile: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
      state.username = null;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(validateToken.rejected, (state) => {
      state.token = null;
      state.username = null;
    });
  },
});

export const {
  setToken,
  setLoading,
  setError,
  clearToken,
  setProfile,
  setUsername,
} = authSlice.actions;
export default authSlice.reducer;
