import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import blogPosts from "../data/blogPostsData.json";
import { generateSlug } from "../utils";
import LoadingScreen from "../components/LoadingScreen";
import LazyImage from "../components/LazyImage";

const { REACT_APP_S3_URL } = process.env;

const BlogsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleBlogClick = (title: string) => {
    window.open(`/blog/${encodeURIComponent(generateSlug(title))}`, "_target");
  };

  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-24 sm:pb-24 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8 lg:mb-14">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-xl font-semibold tracking-widest text-stone-800 sm:text-3xl">
              blogs
            </h1>
          </div>
          <div className="blog px-8 font-heading lg:flex lg:flex-col lg:gap-y-20 lg:px-24">
            {blogPosts.map((post) => (
              <div
                key={post.id}
                className="card w-full cursor-pointer gap-x-10 lg:relative lg:flex lg:justify-between"
                onClick={() => handleBlogClick(post.title)}
              >
                <div className="w-full">
                  <LazyImage
                    src={post.imageUrl}
                    alt={post.title}
                    className="mb-4 mt-10 h-auto lg:my-0 lg:h-[400px]"
                  />
                </div>
                <div className="w-full">
                  <div className="flex items-center gap-x-4 font-semibold lg:gap-x-12">
                    <h2 className="date uppercase">{post.date}</h2>
                    <h2 className="read-time uppercase text-[#8b8b8b]">
                      {post.readTime}
                    </h2>
                  </div>
                  <h1 className="title my-4 w-[90%] text-2xl font-medium leading-tight lg:my-6 lg:text-4xl">
                    {post.title}
                  </h1>
                  <p className="description text-xl lg:w-[90%]">
                    {post.description}
                  </p>
                  <h3 className="written-by my-2 font-semibold lg:absolute lg:bottom-0 lg:my-0 lg:self-end">
                    | {post.author}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogsPage;
