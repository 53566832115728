const AboutUs = () => {
  return (
    <section id="about">
      <h2 className="bg-white bg-opacity-80 px-8 pb-4 pt-6 text-center font-tan-moncheri text-base font-semibold uppercase leading-loose tracking-widest text-stone-700 md:text-xl lg:pb-5 lg:pt-9 lg:text-3xl">
        who we are ? what we do ? why we do ?
      </h2>
      <div className="about-content">
        <div className="m-auto w-full px-8 py-14 text-center font-heading text-xl md:px-10 md:py-16 lg:grid lg:w-[80%] lg:grid-cols-2 lg:gap-x-16 lg:px-20 lg:py-24 lg:text-sub-heading">
          <div>
            <p className="rounded-2xl bg-white bg-opacity-50 px-8 pb-8 pt-10 leading-relaxed lg:mb-16 lg:px-12 lg:pb-10 lg:pt-12">
              We harness the power of technology not just to connect, but to
              heal—to ensure that every interaction nurtures both mind and soul.
              We believe in the transformative magic of sharing, knowing that in
              each narrative lies the potential for healing and growth.
            </p>
            <p className="my-8 rounded-2xl bg-white bg-opacity-50 px-8 pb-8 pt-10 leading-relaxed lg:my-0 lg:px-12 lg:pb-10 lg:pt-12">
              We're more than just a platform; we're a beacon of hope in the
              digital realm, providing solace to those navigating life's storms.
              Our mission is simple yet profound: to create a nurturing
              environment where individuals can share their struggles openly
              while receiving invaluable insights and support from a caring
              community.
            </p>
          </div>
          <div className="flex items-center justify-center">
            <p className="flex h-fit items-center rounded-2xl bg-white bg-opacity-50 px-8 pb-8 pt-10 leading-relaxed lg:px-12 lg:pb-10 lg:pt-12">
              In a world where mental well-being is often overlooked, especially
              in India where millions grapple with silent battles, we stand firm
              in our commitment to fostering positive change. Through our app
              and website, we've cultivated a diverse tapestry of voices,
              including survivors, therapists, psychologists, and everyday
              heroes. Here, conversations span a myriad of topics—from financial
              woes to familial dynamics—bolstered by the unifying thread of
              empathy and understanding.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
