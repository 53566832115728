const { REACT_APP_S3_URL } = process.env;

export const paymentImageUrls: { [key: string]: string } = {
  mastercard: `${REACT_APP_S3_URL}/assets/payments/mastercard.png`,
  visa: `${REACT_APP_S3_URL}/assets/payments/visa.png`,
  maestro: `${REACT_APP_S3_URL}/assets/payments/maestro.png`,
  bajaj: `${REACT_APP_S3_URL}/assets/payments/bajaj.png`,
  diners: `${REACT_APP_S3_URL}/assets/payments/diners.png`,
  "american express": `${REACT_APP_S3_URL}/assets/payments/amex-card.png`,
  rupay: `${REACT_APP_S3_URL}/assets/payments/rupay.png`,
  netbanking: `${REACT_APP_S3_URL}/assets/payments/netbanking.jpeg`,
  wallet: `${REACT_APP_S3_URL}/assets/payments/wallet.jpeg`,
  upi: `${REACT_APP_S3_URL}/assets/payments/upi.jpeg`,
};
