import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import LandingPage from "./pages/LandingPage";
import LoginSignupPage from "./pages/LoginSignupPage";
import BlogsPage from "./pages/BlogsPage";
import BlogContentPage from "./pages/BlogContentPage";
import PageNotFound from "./pages/PageNotFound";
import SpeakItOutPage from "./pages/SpeakItOutPage";
import TncPage from "./pages/TncPage";
import ShippingPolicyPage from "./pages/ShippingPolicyPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SupportPage from "./pages/SupportPage";
import RefundPolicyPage from "./pages/RefundPolicyPage";
import PaymentHistoryPage from "./pages/PaymentHistoryPage";
import ScheduleCalendar from "./components/ScheduleCalendar";
import "./App.css";

function App() {
  return (
    <div className="background-container">
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<LandingPage />} />
          <Route path="/shippingpolicy" element={<ShippingPolicyPage />} />
          <Route path="/tnc" element={<TncPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/refund" element={<RefundPolicyPage />} />
          <Route path="/support" element={<SupportPage />} />{" "}
          <Route path="/*" element={<SpeakItOutPage />} />
          <Route path="/blog" element={<BlogsPage />} />
          <Route path="/blog/:encodedTitle" element={<BlogContentPage />} />
          <Route path="/login" element={<LoginSignupPage />} />
          <Route path="/signup" element={<LoginSignupPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/reschedule/:productId/:calendarXEventId/:calendarXBookingId"
            element={<ScheduleCalendar />}
          />
          <Route
            path="/schedule/:productId/:calendarXEventId"
            element={<ScheduleCalendar />}
          />
          <Route path="/payments-history" element={<PaymentHistoryPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
