import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import authReducer from "./authSlice";
import postReducer from "./postSlice";
import postDetailReducer from "./postDetailSlice";
import paymentHistoryReducer from "./paymentHistorySlice";
import purchasesReducer from "./purchasesSlice";
import sessionsReducer from "./sessionsSlice";
import slotsReducer from "./slotsSlice";
import bookingsReducer from "./bookingSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "posts", "postDetail", "paymentHistory", "purchases"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  posts: postReducer,
  postDetail: postDetailReducer,
  paymentHistory: paymentHistoryReducer,
  purchases: purchasesReducer,
  sessions: sessionsReducer,
  slots: slotsReducer,
  bookings: bookingsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
