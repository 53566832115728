import React, { useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentHistory } from "../store/paymentHistorySlice";
import { RootState, AppDispatch } from "../store/index";
import { paymentImageUrls } from "../constants/cardImageUrls";
import PaymentCard from "../components/PaymentCard";

interface ListItem {
  label: string;
  url: string;
}

const listItems: ListItem[] = [
  { label: "Privacy Policy", url: "/privacy" },
  { label: "Refund Policy", url: "/refund" },
  { label: "Terms & Conditions", url: "/tnc" },
  { label: "Support", url: "/support" },
];

const { REACT_APP_S3_URL } = process.env;

const PaymentHistoryPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const paymentHistory = useSelector(
    (state: RootState) => state.paymentHistory,
  );

  useEffect(() => {
    dispatch(fetchPaymentHistory());
  }, [dispatch]);

  return (
    <section className="flex h-screen flex-col bg-white font-community xl:flex-row">
      <div className="flex w-full flex-col justify-between bg-black px-5 py-3 xl:w-[25%] xl:px-10 xl:pb-10 xl:pt-20 3xl:w-[20%]">
        <div>
          <a href="/">
            <img
              src={`${REACT_APP_S3_URL}/assets/one-life-logo-white.png`}
              alt="one-life-logo"
              className="h-10 xl:h-12"
            />
          </a>
          <div className="hidden xl:block">
            <p className="mt-4 text-base font-semibold leading-normal text-white xl:mt-16 xl:text-xl">
              Manage your One Life Healthcare billing settings
            </p>
            <p
              className="mt-4 flex cursor-pointer items-center gap-x-3 text-light xl:mt-6"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeft /> Go back
            </p>
          </div>
        </div>
        <div className="hidden text-wrap text-left font-community text-[10px] text-[#aaaaaa] xl:mt-0 xl:block xl:text-[12px]">
          <ul className="flex flex-wrap items-center justify-start gap-x-2 pb-1 xl:gap-x-4">
            {listItems.map((item, index) => (
              <li className="hover:scale-105 hover:opacity-70" key={index}>
                {item.url.startsWith("/#") ? (
                  <HashLink smooth to={item.url}>
                    {item.label}
                  </HashLink>
                ) : (
                  <a href={item.url}>{item.label}</a>
                )}
              </li>
            ))}
          </ul>
          <p>© Fly Fare Technologies LLP 2024. All rights reserved.</p>
        </div>
      </div>
      <div className="flex w-full flex-col overflow-x-auto overflow-y-auto  xl:w-[75%] xl:px-12 xl:pb-10 xl:pt-32 3xl:w-[80%]">
        <div className="h-screen px-4 py-8">
          <div className="flex items-center justify-between">
            <p className="flex items-center font-bold uppercase text-primary text-opacity-75">
              <FaArrowLeft
                className="mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              your payment history
            </p>
          </div>
          <div className="my-3 w-full border-t border-primary opacity-30"></div>
          <div className="w-full overflow-x-auto">
            <div className="hidden xl:block">
              <table className="min-w-full border-collapse border-b border-gray-200 text-sm">
                <thead className="bg-stone-50">
                  <tr>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Date
                    </th>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Title
                    </th>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Description
                    </th>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Psychologist Name
                    </th>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Amount
                    </th>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Payment Status
                    </th>
                    <th className="border-b border-gray-200 px-2 py-2 text-left md:px-4">
                      Payment Method
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistory.data.map((detail, index) => (
                    <tr key={index}>
                      <td className="border-b border-t border-gray-200 px-2 py-2 md:px-4">
                        {new Date(detail.createdAt).toLocaleDateString()}
                      </td>
                      <td className="border-b border-t border-gray-200 px-2 py-2 md:px-4">
                        {detail.product.title}
                      </td>
                      <td className="border-b border-t border-gray-200 px-2 py-2 md:px-4">
                        {detail.product.description}
                      </td>
                      <td className="border-b border-t border-gray-200 px-2 py-2 md:px-4">
                        {detail.psychologist.name}
                      </td>
                      <td className="border-b border-t border-gray-200 px-2 py-2 md:px-4">
                        {detail.payment.currency === "INR"
                          ? `₹${detail.payment.amount / 100}`
                          : `$${detail.payment.amount / 100}`}
                      </td>
                      <td className="border-b border-t border-gray-200 px-2 py-2 md:px-4">
                        {detail.payment.paid ? (
                          <span className="rounded bg-green-100 px-2 py-1 text-green-700">
                            Paid
                          </span>
                        ) : (
                          <span className="rounded bg-red-100 px-2 py-1 text-red-500">
                            Failed
                          </span>
                        )}
                      </td>
                      <td className="flex items-center border-gray-200 px-2 py-2 md:px-4">
                        {detail.payment.method === "card" ? (
                          <>
                            <img
                              src={
                                paymentImageUrls[
                                  detail.payment.card.network.toLowerCase()
                                ]
                              }
                              alt={detail.payment.card.network}
                              className={`mr-2 ${detail.payment.card.network.toLowerCase() === "bajaj" || detail.payment.card.network.toLowerCase() === "rupay" ? "h-8 w-16" : "h-6 w-9"}`}
                            />
                            <span className="text-sm">
                              ●●●● {detail.payment.card.last4}
                            </span>
                          </>
                        ) : detail.payment.method in paymentImageUrls ? (
                          <>
                            <img
                              src={paymentImageUrls[detail.payment.method]}
                              alt={detail.payment.method}
                              className="mr-2 h-6 w-6"
                            />
                            <span>
                              {detail.payment.method === "upi"
                                ? "UPI"
                                : detail.payment.method
                                    .charAt(0)
                                    .toUpperCase() +
                                  detail.payment.method.slice(1)}
                            </span>
                          </>
                        ) : (
                          "nil"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="block xl:hidden">
              {paymentHistory.data.map((detail, index) => (
                <PaymentCard detail={detail} key={index} />
              ))}
            </div>
          </div>{" "}
        </div>
        <div className="text-wrap bg-black px-4 py-4 text-left font-community text-[10px] text-[#aaaaaa] xl:fixed xl:bottom-0 xl:hidden xl:w-[25%] xl:py-10 xl:text-[12px]">
          <ul className="flex flex-wrap items-center justify-center gap-x-2 pb-1 xl:gap-x-4">
            {listItems.map((item, index) => (
              <li className="hover:scale-105 hover:opacity-70" key={index}>
                {item.url.startsWith("/#") ? (
                  <HashLink smooth to={item.url}>
                    {item.label}
                  </HashLink>
                ) : (
                  <a href={item.url}>{item.label}</a>
                )}
              </li>
            ))}
          </ul>
          <p className="text-center">
            © Fly Fare Technologies LLP 2024. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PaymentHistoryPage;
