import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faPlus,
  faSearch,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import { clearToken } from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import CreatePost from "./CreatePost";
import { FaUserCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";

interface Post {
  _id: string;
  title: string;
  body: string;
  tags: string[];
}

const Header = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchFocused, setSearchFocused] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [postToEdit, setPostToEdit] = useState<Post | null>(null);
  const [dropdownTimeout, setDropdownTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );

  const tags = [
    "confession",
    "query",
    "question",
    "distress",
    "self help",
    "daily challenge",
  ];

  const username = useSelector((state: RootState) => state.auth.username);
  const email = useSelector((state: RootState) => state.auth.profile?.email);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleTagClick = (tag: string) => {
    setSearchValue((prev) => {
      const params = new URLSearchParams(window.location.search);
      let existingTags = params.getAll("tags");
      existingTags = existingTags.map((t) => t.replace(/-/g, " "));
      const tagIndex = existingTags.indexOf(tag);
      if (tagIndex !== -1) {
        existingTags.splice(tagIndex, 1);
      } else {
        existingTags.push(tag);
      }
      const formattedTags = existingTags.map((t) => t.replace(/\s+/g, "-"));
      const newTags = formattedTags.map((t) => `tags=${t}`).join("&");
      navigate(`/?${newTags}`);
      return existingTags.join(", ");
    });
    setSearchFocused(false);
  };

  const handleLogout = () => {
    dispatch(clearToken());
    setDropdownOpen(false);
    navigate("/home");
  };

  const handleCreatePostClick = () => {
    if (!username) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }
    setPostToEdit(null);
    setShowCreatePost(true);
  };

  const handlePostCreated = (postId: string) => {
    setShowCreatePost(false);
    navigate(`/post/${postId}`);
  };

  const handleClearSearch = () => {
    setSearchValue("");
    navigate("/");
  };

  const handleMouseEnterDropdown = () => {
    if (dropdownTimeout) {
      clearTimeout(dropdownTimeout);
      setDropdownTimeout(null);
    }
  };

  const handleMouseLeaveDropdown = () => {
    const timeout = setTimeout(() => {
      setDropdownOpen(false);
    }, 300);
    setDropdownTimeout(timeout);
  };

  const handleNavigate = (path: string, state?: any) => {
    navigate(path, { state });
    setDropdownOpen(false);
  };

  return (
    <>
      <header className="flex h-24 w-full items-center justify-between px-5 font-community">
        <h1 className="text-3xl font-bold text-light">Speak It Out!</h1>
        <div className="relative flex items-center gap-x-3">
          <div className="relative">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search..."
              className="search w-80 rounded-lg border border-primary bg-secondary p-2 pl-10 text-[15px] text-light placeholder-primary"
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setTimeout(() => setSearchFocused(false), 200)}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 top-1/2 -translate-y-1/2 transform text-[#505050]"
            />
            {searchValue && (
              <button
                onClick={handleClearSearch}
                className="absolute right-3 top-1/2 -translate-y-1/2 transform text-[#505050]"
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            )}
            {searchFocused && (
              <div className="absolute left-0 right-0 z-50 mt-2 w-auto rounded-lg border border-[#999999b0] bg-secondary shadow-lg">
                <ul className="flex flex-wrap items-center justify-start gap-2 px-3 py-4">
                  {tags.map((tag, index) => (
                    <li
                      key={index}
                      className={`cursor-pointer rounded-full border border-primary px-4 py-2 text-center text-sm text-light hover:bg-[#5c5c5cc2] ${
                        searchValue.includes(tag) ||
                        (tag === "self help" &&
                          searchValue.includes("self-help")) ||
                        (tag === "daily challenge" &&
                          searchValue.includes("daily-challenge"))
                          ? "bg-[#404040c2]"
                          : "bg-[#40404038]"
                      }`}
                      onClick={() => handleTagClick(tag)}
                    >
                      {tag}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-primary bg-light hover:scale-105 hover:opacity-75"
            onClick={handleCreatePostClick}
            data-tooltip-id="create-post"
            data-tooltip-content="Create Post"
          >
            <FontAwesomeIcon icon={faPlus} className="text-secondary" />
          </div>
          <Tooltip
            id="create-post"
            noArrow
            delayShow={1000}
            style={{
              backgroundColor: "#d6d6d6",
              color: "#171616",
              padding: "3px 6px",
              fontSize: "13px",
              position: "absolute",
              fontFamily: "inherit",
              zIndex: "1",
            }}
          />
          {!username ? (
            <button
              className="h-10 w-36 transform rounded-full bg-light py-2 text-[15px] tracking-wide text-black transition-transform hover:scale-105"
              onClick={() => navigate("/login")}
            >
              Login/Sign up
            </button>
          ) : (
            <div
              className="relative z-50 inline-block text-left"
              onMouseEnter={handleMouseEnterDropdown}
              onMouseLeave={handleMouseLeaveDropdown}
            >
              <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex cursor-pointer items-center"
              >
                <FaUserCircle className="h-10 w-10 text-light" />
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="ml-2 text-light"
                />
              </div>
              {dropdownOpen && (
                <div
                  ref={dropdownRef}
                  className="absolute right-0 mt-2 w-72 origin-top-right rounded-md bg-secondary shadow-lg ring-1 ring-black ring-opacity-5"
                >
                  <div className="flex items-center px-4 py-2">
                    <FaUserCircle className="mr-3 h-10 w-10 text-light" />
                    <div>
                      <p className="text-lg font-medium text-light">
                        {username}
                      </p>
                      <p className="text-sm font-medium text-tertiary">
                        {email}
                      </p>
                    </div>
                  </div>
                  <div className="border-t border-primary"></div>
                  <div>
                    <a
                      href="/home"
                      className="block px-4 py-2 text-base text-tertiary hover:bg-[#3d3d3d67]"
                    >
                      Home
                    </a>
                    <a
                      href="/profile"
                      className="block px-4 py-2 text-base text-tertiary hover:bg-[#3d3d3d67]"
                    >
                      Profile
                    </a>
                    <p
                      onClick={() =>
                        handleNavigate("/profile", {
                          activeTab: "myPosts",
                        })
                      }
                      className="block cursor-pointer px-4 py-2 text-base text-tertiary hover:bg-[#3d3d3d67]"
                    >
                      My Posts
                    </p>
                    <p
                      onClick={() =>
                        handleNavigate("/profile", {
                          activeTab: "purchases",
                        })
                      }
                      className="block cursor-pointer px-4 py-2 text-base text-tertiary hover:bg-[#3d3d3d67]"
                    >
                      Purchases
                    </p>
                    <p
                      onClick={() =>
                        handleNavigate("/profile", {
                          activeTab: "sessions",
                        })
                      }
                      className="block cursor-pointer px-4 py-2 text-base text-tertiary hover:bg-[#3d3d3d67]"
                    >
                      Sessions
                    </p>
                    <a
                      href="/payments-history"
                      className="block px-4 py-2 text-base text-tertiary hover:bg-[#3d3d3d67]"
                    >
                      Payment History
                    </a>
                  </div>
                  <div className="border-t border-primary"></div>
                  <button
                    onClick={handleLogout}
                    className="block w-full rounded-md px-4 py-2 text-left text-base text-tertiary hover:bg-[#3d3d3d67]"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </header>
      {showCreatePost && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-full max-w-3xl rounded-lg bg-primary p-6 text-light">
            <button
              className="absolute right-6 top-4"
              onClick={() => setShowCreatePost(false)}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
            <CreatePost
              onPostCreated={handlePostCreated}
              postToEdit={postToEdit}
              onClose={() => setShowCreatePost(false)} // Pass the onClose function
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
