import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ChatBot from "../components/ChatBot";
import LoadingScreen from "../components/LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const ContactPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 200);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section
        id="contact"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-28 sm:pb-20 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 mb-6 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white bg-opacity-40 p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-xl font-semibold tracking-widest text-stone-800 sm:text-3xl">
              Contact us
            </h1>
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:py-6 sm:text-[20px] lg:px-24">
            <div>
              <h1 className="font-medium uppercase underline">
                Contact Information
              </h1>
              <p className="my-3 sm:my-5">
                We do not offer refunds for any payments made for our services,
                including but not limited to subscription fees, credits, or
                promotional offers. Once a payment is made, it is
                non-refundable.
              </p>
              <p>
                <FontAwesomeIcon icon={faEnvelope} /> : You can email us at{" "}
                <span className="font-medium">support@onelifehealth.care</span>{" "}
                for any inquiries or assistance you may need. Our support team
                will respond to your email as soon as possible.
              </p>
              <p className="my-3">
                <FontAwesomeIcon icon={faInstagram} /> : You can also reach out
                to us on Instagram{" "}
                <span className="font-medium">@onelifehealth_care</span> by
                sending us a direct message. Our Instagram page is monitored
                regularly, and we will do our best to assist you promptly.
              </p>
            </div>
            <div>
              <h1 className="my-3 font-medium uppercase underline sm:my-5">
                Hours of Operation
              </h1>
              <p className="my-3 sm:my-5">
                Our support team is available to assist you during the following
                hours: <br />
                <span className="font-medium">Monday to Friday</span> : 9:00 AM
                to 6:00 PM (GMT+5:30) <br />{" "}
                <span className="font-medium">
                  Closed on weekends and public holidays
                </span>
              </p>
            </div>
            <div>
              <h1 className="my-3 font-medium uppercase underline sm:my-5">
                Response Time
              </h1>
              <p className="my-3 sm:my-5">
                We strive to respond to all inquiries and messages within 24-48
                hours during our hours of operation. However, please note that
                response times may vary depending on the volume of inquiries
                received.
              </p>
            </div>
            <div>
              <h1 className="my-3 font-medium uppercase underline sm:my-5">
                Please Note:
              </h1>
              <p className="my-3 sm:my-5">
                For urgent matters or technical issues, please include{" "}
                <span className="font-medium">"URGENT"</span> in the subject
                line of your email or message.
              </p>
            </div>
          </div>
          <p className="m-auto w-full px-8 font-heading text-lg sm:text-[20px] lg:px-24">
            When contacting us, please provide as much detail as possible about
            the issue you are experiencing or the assistance you require. This
            will help us understand your needs and provide a more effective
            response. Thank you for choosing{" "}
            <span className="font-medium">One Life HealthCare</span> for your
            mental health support and community needs. We are here to assist you
            every step of the way.
          </p>
        </div>
      </section>
      <ChatBot />
      <Footer />
    </>
  );
};

export default ContactPage;
