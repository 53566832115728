import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../styles/Calendar.css";
import { FaCalendarAlt, FaCheckCircle, FaClock, FaGlobe } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSlots,
  bookSession,
  fetchSlotsForReschedule,
  rescheduleBookedSession,
} from "../store/slotsSlice";
import { fetchPurchases } from "../store/purchasesSlice";
import { RootState, AppDispatch } from "../store";
import { format, isBefore, startOfMonth, getHours } from "date-fns";
import LoadingScreen from "./LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

interface Slot {
  time: string;
}

const ScheduleComponent: React.FC = () => {
  const { productId, calendarXEventId, calendarXBookingId } = useParams<{
    productId: string;
    calendarXEventId: string;
    calendarXBookingId: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [date, setDate] = useState(new Date());
  const [loadedMonth, setLoadedMonth] = useState<string | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<{
    date: string;
    startTime: string;
    endTime: string;
  } | null>(null);

  const selectedPurchase = useSelector((state: RootState) =>
    state.purchases.data.find((purchase) => purchase._id === productId),
  );

  const { slots, event, status } = useSelector(
    (state: RootState) => state.slots,
  );

  useEffect(() => {
    if (calendarXBookingId && loadedMonth !== format(date, "yyyy-MM")) {
      dispatch(
        fetchSlotsForReschedule({
          calendarXBookingId,
          yearMonth: format(date, "yyyy-MM"),
        }),
      );
      dispatch(fetchPurchases());
      setLoadedMonth(format(date, "yyyy-MM"));
    } else if (
      productId &&
      calendarXEventId &&
      loadedMonth !== format(date, "yyyy-MM")
    ) {
      dispatch(
        fetchSlots({
          productId,
          calendarXEventId,
          yearMonth: format(date, "yyyy-MM"),
        }),
      );
      dispatch(fetchPurchases());
      setLoadedMonth(format(date, "yyyy-MM"));
    }
  }, [
    dispatch,
    productId,
    calendarXEventId,
    calendarXBookingId,
    date,
    loadedMonth,
  ]);

  const handleDateChange = (value: Date | Date[] | null) => {
    if (value instanceof Date) {
      setDate(value);
    }
  };

  const handleActiveStartDateChange = ({
    activeStartDate,
  }: {
    activeStartDate: Date | null;
  }) => {
    if (activeStartDate) {
      const firstDateOfMonth = startOfMonth(activeStartDate);
      const now = new Date();
      let dateToSet = firstDateOfMonth;

      if (
        activeStartDate.getFullYear() === now.getFullYear() &&
        activeStartDate.getMonth() === now.getMonth()
      ) {
        dateToSet = isBefore(firstDateOfMonth, now) ? now : firstDateOfMonth;
      }

      setDate(dateToSet);
      if (calendarXBookingId && loadedMonth !== format(date, "yyyy-MM")) {
        dispatch(
          fetchSlotsForReschedule({
            calendarXBookingId,
            yearMonth: format(date, "yyyy-MM"),
          }),
        );
        setLoadedMonth(format(dateToSet, "yyyy-MM"));
      }
      if (
        productId &&
        calendarXEventId &&
        loadedMonth !== format(date, "yyyy-MM")
      ) {
        dispatch(
          fetchSlots({
            productId: productId as string,
            calendarXEventId: calendarXEventId as string,
            yearMonth: format(dateToSet, "yyyy-MM"),
          }),
        );
        setLoadedMonth(format(dateToSet, "yyyy-MM"));
      }
    }
  };

  const eventAvailability = event ? event.availability : null;

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateString = format(date, "yyyy-MM-dd");

    if (
      view === "month" &&
      (isBefore(date, today) || !slots[dateString]?.length)
    ) {
      return "past-date";
    }
    if (view === "month" && slots[dateString]?.length > 0) {
      return "available-slot";
    }
    if (view === "month" && date.toDateString() === today.toDateString()) {
      return "react-calendar__tile--now";
    }
    return "";
  };

  const selectedDateString = format(date, "yyyy-MM-dd");
  const selectedSlots = slots[selectedDateString] || [];

  const handleSlotClick = (slot: Slot) => {
    setSelectedSlot(slot);
    setShowForm(true);
  };

  const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateString = format(date, "yyyy-MM-dd");

    if (view === "month") {
      return isBefore(date, today) || !slots[dateString]?.length;
    }
    return false;
  };

  const handleBack = () => {
    setShowForm(false);
    setSelectedSlot(null);
  };

  const handleConfirm = () => {
    if (!event) return;
    const startTime = getHours(new Date(selectedSlot!.time)) * 60;
    const endTime = startTime + event.durationInMinutes;

    if (productId && calendarXEventId && !calendarXBookingId) {
      dispatch(
        bookSession({
          productId: productId!,
          calendarXEventId: calendarXEventId!,
          client: {
            name: clientName,
            email: clientEmail,
          },
          notes,
          date: format(date, "yyyy-MM-dd"),
          startTime,
          endTime,
        }),
      ).then((action) => {
        if (bookSession.fulfilled.match(action)) {
          setBookingConfirmed(true);
          setBookingDetails({
            date: format(date, "EEEE, MMMM d, yyyy"),
            startTime: format(new Date(selectedSlot!.time), "hh:mmaaa"),
            endTime: format(
              new Date(
                new Date(selectedSlot!.time).getTime() +
                  event.durationInMinutes * 60000,
              ),
              "hh:mmaaa",
            ),
          });
        }
      });
      return;
    }

    if (calendarXBookingId && calendarXEventId && productId) {
      dispatch(
        rescheduleBookedSession({
          calendarXBookingId,
          client: {
            name: clientName,
            email: clientEmail,
          },
          notes,
          date: format(date, "yyyy-MM-dd"),
          startTime,
          endTime,
        }),
      ).then((action) => {
        if (rescheduleBookedSession.fulfilled.match(action)) {
          setBookingConfirmed(true);
          setBookingDetails({
            date: format(date, "EEEE, MMMM d, yyyy"),
            startTime: format(new Date(selectedSlot!.time), "hh:mmaaa"),
            endTime: format(
              new Date(
                new Date(selectedSlot!.time).getTime() +
                  event.durationInMinutes * 60000,
              ),
              "hh:mmaaa",
            ),
          });
        }
      });
      return;
    }
  };

  return (
    <>
      {status === "loading" ? (
        <LoadingScreen />
      ) : (
        <div className="flex h-screen flex-col items-center justify-center bg-[#111111] p-4 font-community">
          {bookingConfirmed ? (
            <div className="mb-5 flex h-fit w-[500px] items-center justify-center bg-[#111111] font-community">
              <div className="rounded-lg border border-primary bg-primary px-6 py-8 text-center">
                <FaCheckCircle className="mb-2 w-full text-4xl text-light" />
                <h2 className="mb-2 text-xl font-bold text-white">
                  This meeting is scheduled
                </h2>
                <p className="text-sm text-light text-opacity-85">
                  We sent an email with a calendar invitation with the details
                  to everyone.
                </p>
                <p className="mt-6 px-5 text-[15px] text-light">
                  A meeting has been scheduled on {bookingDetails?.date} from{" "}
                  {bookingDetails?.startTime} - {bookingDetails?.endTime} with{" "}
                  {event?.psychologist.name}{" "}
                </p>
                <div className="mt-6">
                  <button
                    className="rounded bg-light px-4 py-2 text-base text-primary"
                    onClick={() =>
                      navigate("/profile", {
                        state: { activeTab: "sessions" },
                      })
                    }
                  >
                    Check Sessions
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {showForm ? (
                <div className="mb-2 flex h-full w-full overflow-hidden rounded-lg border border-primary bg-primary lg:h-[65%] lg:w-[50%] 3xl:h-[50%]">
                  <div className="flex w-full bg-primary">
                    <div className=" w-[40%] border-r border-primary p-6">
                      <div className="flex items-center space-x-4">
                        <img
                          src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${event?.psychologist.image}`}
                          alt={event?.psychologist.name}
                          className="h-12 w-12 rounded-full"
                        />
                        <div>
                          <h2 className="text-lg font-bold text-white">
                            {event?.psychologist.name}
                          </h2>
                          <p className="text-sm text-light text-opacity-85">
                            {event?.psychologist.title}
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 lg:mt-6">
                        <p className="text-lg font-semibold text-white">
                          {event?.title}
                        </p>
                        <p className="text-sm text-light text-opacity-85">
                          {event?.description}
                        </p>
                        <div className="mt-4 flex items-center space-x-2 text-[15px] text-light text-opacity-85 lg:mt-8">
                          <FaClock className="h-4 w-4" />
                          <span>{event?.durationInMinutes} minutes</span>
                        </div>
                        <div className="mt-2 text-[15px] text-light text-opacity-85">
                          <div className="flex gap-x-3">
                            <FaCalendarAlt className="mt-1" />
                            {format(date, "EEEE, MMMM d, yyyy")},<br />
                            {`${format(new Date(selectedSlot!.time), "hh:mmaaa")} - ${format(
                              new Date(
                                new Date(selectedSlot!.time).getTime() +
                                  event!.durationInMinutes * 60000,
                              ),
                              "hh:mmaaa",
                            )}`}
                          </div>
                        </div>
                        <div className="mt-2 flex items-center space-x-2 text-[15px] text-light text-opacity-85">
                          <img
                            src={`${REACT_APP_S3_URL}/assets/icons/google-meet.png`}
                            alt={event?.provider}
                            className="h-5 w-5 rounded-full"
                          />{" "}
                          <span>
                            {event?.provider === "google-meet"
                              ? "Google Meet"
                              : event?.provider}
                          </span>
                        </div>
                        <div className="mt-2 flex items-center space-x-2 text-[15px] text-light text-opacity-85">
                          <FaGlobe className="h-4 w-4" />
                          <span>
                            {eventAvailability
                              ? eventAvailability.timezone
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="w-[60%] bg-secondary p-6">
                      <div className="mb-4">
                        <label className="mb-2 block text-white" htmlFor="name">
                          Your Name
                        </label>
                        <input
                          id="name"
                          type="text"
                          placeholder="name"
                          className="w-full rounded-md border border-primary bg-primary p-2 text-sm text-light placeholder:text-tertiary focus:border-[#535353] focus:outline-none 3xl:text-[15px]"
                          value={clientName}
                          onChange={(e) => setClientName(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          className="mb-2 block text-white"
                          htmlFor="email"
                        >
                          Email address
                        </label>
                        <input
                          id="email"
                          type="email"
                          placeholder="email"
                          className="w-full rounded-md border border-primary bg-primary p-2 text-sm text-light placeholder:text-tertiary focus:border-[#535353] focus:outline-none 3xl:text-[15px]"
                          value={clientEmail}
                          onChange={(e) => setClientEmail(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          className="mb-2 block text-white"
                          htmlFor="notes"
                        >
                          Additional Notes
                        </label>
                        <textarea
                          id="notes"
                          className="h-20 w-full rounded-md border border-primary bg-primary p-2 text-sm text-light placeholder:text-tertiary focus:border-[#535353] focus:outline-none 3xl:text-[15px]"
                          placeholder="please share anything that will help us prepare for our meeting"
                          value={notes}
                          onChange={(e) => setNotes(e.target.value)}
                        />
                      </div>
                      <div className="flex justify-between">
                        <button
                          className="rounded bg-primary px-4 py-2 text-light"
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        <button
                          className="rounded bg-light px-4 py-1 text-base text-primary"
                          onClick={handleConfirm}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex h-full w-full overflow-hidden rounded-lg border border-primary bg-primary lg:h-[45%] lg:w-[60%]">
                  <div className="flex w-full flex-col bg-primary p-4 lg:w-[30%] lg:p-6">
                    <div className="flex items-center space-x-4">
                      <img
                        src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${event?.psychologist.image}`}
                        alt={event?.psychologist.name}
                        className="h-12 w-12 rounded-full"
                      />
                      <div>
                        <h2 className="text-lg font-bold text-white">
                          {event?.psychologist.name}
                        </h2>
                        <p className="text-sm text-light text-opacity-85">
                          {event?.psychologist.title}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 lg:mt-6">
                      <p className="text-lg font-semibold text-white">
                        {event?.title}
                      </p>
                      <p className="text-sm text-light text-opacity-85">
                        {event?.description}
                      </p>
                      <div className="mt-4 flex items-center space-x-2 text-[15px] text-light text-opacity-85 lg:mt-8">
                        <FaClock className="h-4 w-4" />
                        <span>{event?.durationInMinutes} minutes</span>
                      </div>
                      <div className="mt-2 flex items-center space-x-2 text-[15px] text-light text-opacity-85">
                        <img
                          src={`${REACT_APP_S3_URL}/assets/icons/google-meet.png`}
                          alt={event?.provider}
                          className="h-5 w-5 rounded-full"
                        />{" "}
                        <span>
                          {event?.provider === "google-meet"
                            ? "Google Meet"
                            : event?.provider}
                        </span>
                      </div>
                      <div className="mt-2 flex items-center space-x-2 text-[15px] text-light text-opacity-85">
                        <FaGlobe className="h-4 w-4" />
                        <span>
                          {eventAvailability ? eventAvailability.timezone : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full flex-col border-t border-primary p-4 lg:w-[40%] lg:border-x lg:border-t-0 lg:p-6">
                    <div className="flex flex-grow items-center justify-center">
                      <Calendar
                        value={date}
                        onChange={(value) =>
                          handleDateChange(value as Date | Date[] | null)
                        }
                        className="h-full w-full bg-primary text-white"
                        tileClassName={tileClassName}
                        tileDisabled={tileDisabled}
                        onActiveStartDateChange={({ activeStartDate }) =>
                          handleActiveStartDateChange({ activeStartDate })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex w-full flex-col bg-primary p-4 lg:w-[30%] lg:p-6">
                    <div className="mb-4 flex items-center justify-between">
                      <div className="text-center text-[17px] text-white">
                        {format(date, "EEE MMM d")}
                      </div>
                      <button className="rounded bg-secondary px-4 py-2 text-sm text-light">
                        12h
                      </button>
                    </div>
                    <div className="calendar-scroll mt-2 grid grid-cols-1 gap-y-1.5 overflow-y-auto pb-5 pr-2">
                      {selectedSlots.length > 0 ? (
                        selectedSlots.map((slot, index) => (
                          <button
                            key={index}
                            className="rounded border border-primary bg-secondary px-4 py-2 text-left text-sm text-white"
                            onClick={() => handleSlotClick(slot)}
                          >
                            {format(new Date(slot.time), "hh:mmaaa")}
                          </button>
                        ))
                      ) : (
                        <div className="text-light text-opacity-85">
                          No time slots available
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <a href="/" className="mt-4">
            <img
              src={`${REACT_APP_S3_URL}/assets/one-life-logo-white.png`}
              className="ml-20 h-10 opacity-80"
              alt="one-life-logo"
            />
          </a>
        </div>
      )}
    </>
  );
};

export default ScheduleComponent;
