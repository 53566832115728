import React from "react";

interface FormInputProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  required?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({
  type,
  placeholder,
  value,
  onChange,
  error,
  required = false,
}) => {
  return (
    <div className="mb-2">
      <input
        type={type}
        className="h-12 w-full rounded-md border border-[#d896a5] px-4 text-lg text-black outline-none"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
      />
      {error && <p className="text-base text-red-500">{error}</p>}
    </div>
  );
};

export default FormInput;
