import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const signup = async (payload: {
  username: string;
  email: string;
  password: string;
}) => {
  const response = await axios.post(
    `${REACT_APP_API_URL}/auth/signup`,
    payload,
  );
  return response.data;
};

export const login = async (payload: {
  username: string;
  password: string;
}) => {
  const response = await axios.post(`${REACT_APP_API_URL}/auth/login`, payload);
  return response.data;
};

export const fetchProfile = async (token: string) => {
  const response = await axios.get(`${REACT_APP_API_URL}/auth/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
