import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ChatBot from "../components/ChatBot";
import LoadingScreen from "../components/LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const PrivacyPolicyPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 200);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section
        id="privacy"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-28 sm:pb-20 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 mb-6 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white bg-opacity-40 p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-lg font-semibold tracking-widest text-stone-800 sm:text-3xl">
              Privacy Policy
            </h1>
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:py-6 sm:text-[20px] lg:px-24">
            <div>
              <h1 className="font-medium uppercase underline">Introduction</h1>
              <p className="my-3 sm:my-5">
                At One Life Health Care, we are committed to protecting your
                privacy and ensuring the security of your personal information.
                This Privacy Policy outlines how we collect, use, disclose, and
                protect your information when you use our platform and services.
                By accessing or using our platform, you agree to the terms of
                this Privacy Policy.
              </p>
              <h1 className="mb-2 mt-6 font-medium uppercase underline">
                Information We Collect
              </h1>{" "}
              <p>
                We collect various types of information to provide and improve
                our services, including:
              </p>
              <ul className="list-decimal">
                <li className="ml-5 mt-2 font-medium">Personal Information:</li>
                <p className="my-2">- Name</p>
                <p className="my-2">- Email address</p>
                <p className="my-2">- Phone number</p>
                <p className="my-2">
                  - Payment information (for therapist consultations)
                </p>
                <li className="ml-5 font-medium">Usage Data:</li>
                <p className="my-2">- IP address</p>
                <p className="my-2">- Browser type and version</p>
                <p className="my-2">- Pages you visit on our platform</p>
                <p className="my-2">- Time and date of your visit</p>
                <p className="my-2">- Time spent on those pages</p>
                <p className="my-2">- Other diagnostic data</p>
                <p className="my-2">- Cookies and Tracking Technologies</p>
                <p className="my-4">
                  We use cookies and similar tracking technologies to track
                  activity on our platform and hold certain information.
                </p>
                <h1 className="mb-2 mt-6 font-medium uppercase underline">
                  How We Use Your Information
                </h1>
                <p>
                  We use the information we collect for various purposes,
                  including:
                </p>
                <p className="my-3 font-medium">
                  To Provide and Maintain Our Services:
                </p>
                <p className="my-2">
                  - Facilitate access to community resources
                </p>
                <p className="my-2">
                  - Manage and confirm therapist consultations
                </p>
                <p className="mb-3 mt-6 font-medium">
                  To Provide and Maintain Our Services:
                </p>
                <p className="my-2">
                  - Analyze usage to enhance user experience
                </p>
                <p className="my-2">- Develop new features and services</p>
                <p className="mb-3 mt-6 font-medium">
                  To Communicate with You:
                </p>
                <p className="my-2">
                  - Send updates, notifications, and administrative messages
                </p>
                <p className="my-2">
                  - Respond to your inquiries and provide customer support
                </p>
                <p className="mb-3 mt-6 font-medium">To Process Payments:</p>
                <p className="my-2">
                  - Manage and process payments for therapist consultations
                </p>
                <p className="mb-3 mt-6 font-medium">For Security Purposes:</p>
                <p className="my-2">
                  - Monitor and prevent fraudulent activity
                </p>
                <p className="my-2">
                  - Ensure the security and integrity of our platform
                </p>
                <p className="my-2">- Sharing Your Information</p>
                <p className="mb-3 mt-4">
                  We do not sell, trade, or otherwise transfer your personal
                  information to outside parties, except as described in this
                  Privacy Policy. We may share your information with:
                </p>
              </ul>
              <ul className="my-3 list-disc">
                <div className="items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Service Providers:</li>
                  <p>
                    Third-party vendors who assist us in operating our platform
                    and providing our services (e.g., payment processors, IT
                    service providers)
                  </p>{" "}
                </div>
                <div className="my-2 items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Legal Requirements:</li>
                  <p>
                    If required by law or in response to valid requests by
                    public authorities (e.g., court orders, government agencies)
                  </p>
                </div>
                <div className="items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Business Transfers:</li>
                  <p>
                    In the event of a merger, acquisition, or sale of all or a
                    portion of our assets, your information may be transferred
                    to the new owners.
                  </p>{" "}
                </div>
              </ul>
              <h1 className="mb-3 mt-5 font-medium uppercase underline">
                Security of Your Information
              </h1>
              <p className="my-2">
                We take reasonable measures to protect your personal information
                from unauthorized access, use, alteration, and disclosure.
                However, no method of transmission over the internet or
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your information, we
                cannot guarantee its absolute security.
              </p>
              <h1 className="mb-3 mt-5 font-medium uppercase underline">
                Your Data Protection Rights
              </h1>
              <p className="my-2">
                Depending on your location, you may have the following rights
                regarding your personal information:
              </p>
              <ul className="my-3 list-disc">
                <div className="my-2 items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Access:</li>
                  <p>
                    You have the right to request a copy of the personal
                    information we hold about you.
                  </p>
                </div>
                <div className="my-2 items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Correction:</li>
                  <p>
                    You have the right to request correction of any inaccurate
                    or incomplete personal information.
                  </p>{" "}
                </div>
                <div className="my-2 items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Deletion:</li>
                  <p>
                    You have the right to request the deletion of your personal
                    information, subject to certain conditions.
                  </p>{" "}
                </div>
                <div className="my-2 items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Objection:</li>
                  <p>
                    You have the right to object to our processing of your
                    personal information.
                  </p>{" "}
                </div>
                <div className="my-2 items-center xl:flex">
                  <li className="ml-5 mr-2 font-medium">Restriction:</li>
                  <p>
                    You have the right to request that we restrict the
                    processing of your personal information.
                  </p>{" "}
                </div>
                <li className="ml-5 font-medium">Data Portability:</li>
                <p>
                  You have the right to request the transfer of your personal
                  information to another organization or directly to you, under
                  certain conditions.
                </p>
                <p>
                  To exercise any of these rights, please contact us at{" "}
                  <span className="font-medium">
                    support@onelifehealth.care
                  </span>
                  .
                </p>
              </ul>
              <h1 className="mb-3 mt-5 font-medium uppercase underline">
                Third-Party Links
              </h1>
              <p className="my-2">
                Our platform may contain links to third-party websites. We are
                not responsible for the privacy practices or the content of
                these websites. We encourage you to review the privacy policies
                of any third-party sites you visit.
              </p>
              <h1 className="mb-3 mt-5 font-medium uppercase underline">
                Changes to This Privacy Policy
              </h1>
              <p className="my-2">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
              <h1 className="mb-3 mt-5 font-medium uppercase underline">
                Contact Us
              </h1>
              <p className="my-2">
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{" "}
                <span className="font-medium"> support@onelifehealth.care</span>{" "}
                .
              </p>
              <p className="my-2">
                Thank you for trusting One Life Health Care with your personal
                information.
              </p>
              <p className="mb-1 mt-4 font-medium">Sincerely,</p>
              <p className="font-semibold">The One Life Health Care Team</p>
            </div>
          </div>
        </div>
      </section>
      <ChatBot />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
