import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const { REACT_APP_S3_URL } = process.env;

const NegativeThinking = () => {
  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-70 px-8 pb-6 pt-24 font-heading lg:px-24 lg:pb-20 lg:pt-36"
      >
        <img
          src={`${REACT_APP_S3_URL}/assets/negative-thinking.jpg`}
          alt="negative-thinking"
          className="w-full"
        />
        <h1 className="mb-4 mt-8 text-left text-2xl font-medium leading-snug sm:text-3xl lg:text-5xl lg:leading-tight">
          How does Negative Thinking Affect Mental Health? Steps to Deal with
          Negativity.
        </h1>
        <p className="font-bold sm:text-xl">By Shraddha Pol</p>
        <p className="font-medium sm:text-lg">February 11, 2022</p>
        <div className="my-5 text-lg lg:my-10 lg:text-xl">
          <p>
            The current situation of COVID has compelled us to view ourselves
            and our environment with altered perspective. There have been many
            situations that have led us to think or discern in a way that might
            create a hindrance to optimally maintain our physical and mental
            health. The present situation of constant lockdowns and relaxations
            have conceived severe sense of uncertainty among us which has in
            turn directed us to cultivate a pattern of negative thoughts.
          </p>
          <p className="my-3">
            Perceiving a situation in an unfavourable light has become our
            primary response to any stimulus which might help us be prepared for
            any adversity that might strike us. Experiencing negative thoughts
            every now and then can be considered as normal because we as humans
            cannot think about positivity all the time. We tend to have taken
            the later part of the phrase ‘Hope for the best and prepare for the
            worst’ very seriously and it has become a template for all the
            thoughts that cross our mind. Having to prepare for the worst every
            single time tends to have a very exhaustive effect on our body as
            well as our mind.
          </p>
          <p className="my-3">
            Many environmental and social factors may be responsible for us to
            cultivate a negative thought pattern in the first place. We tend to
            ignore these factors which later on might turn into serious
            self-confidence concerns. Blaming oneself for the situation faced
            may rob us of the faith we have in our being. Negative thought
            pattern tends to prevent us from vocalising our concerns which leads
            to unhealthy expressions of emotions and displacement of anger on
            the people not associated with our situation which further leads to
            strained relationships.
          </p>
          <p className="my-3">
            Constantly thinking about the outcomes and assuming a negative
            consequence tends to put us in a spot where we constantly keep over
            analysing the thoughts leading to a pessimistic attitude which
            prohibits us from looking at the affirmative side of the situation.
            Some psychological and physiological studies have put light on the
            detrimental effects of the aforementioned conditions.
          </p>

          <p>
            Physiological aftermath of having continuous negative thoughts
            include:
          </p>
          <ul className="list-disc">
            <li className="my-2 ml-4 font-medium lg:mt-6">Severe headache</li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">Indigestion</li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">
              High blood pressure
            </li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">
              Hormonal imbalance
            </li>
          </ul>
          <p className="my-5">
            Psychological consequences with unimaginably debilitating effects
            include
          </p>
          <ul className="list-disc">
            <li className="mb-2 ml-4 font-medium lg:mt-6">Depression</li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">Anxiety</li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">
              Self-esteem concerns
            </li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">
              Behavioural problems
            </li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">Chronic worry</li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">
              Obsessive compulsive disorder
            </li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">Panic attacks</li>
            <li className="mb-2 ml-4 font-medium lg:mt-6">Substance use</li>
          </ul>
          <p className="my-2">
            Now that we have seen the ramifications of unhealthy negative
            thinking process, it becomes imperative for us to be able to deal
            with it effectively in order to ensure overall aced well-being.
          </p>
          <h2 className="my-4 mt-6 text-sub-heading font-semibold">
            Rise above and beyond your bigotry
          </h2>
          <p>
            It is understandable that our brain naturally tends to go and think
            the most adverse outcome. Therefore, we have to start practising
            certain behaviours where we can divert our attention to something
            that we like and not keep brooding on our thoughts. Reinvent the
            freeze and run game wherein you freeze your negative thought, divert
            your attention and do something that makes you feel good.
          </p>
          <h2 className="my-4 mt-6 text-sub-heading font-semibold">
            Acknowledge and let it go!
          </h2>
          <p>
            Practise accepting the situation that has taken place be it negative
            or positive. Only after one accepts the condition, can one work on
            letting it go. Think about the circumstances, if they are or aren’t
            in your control. If they are, we can work on making the condition
            better and if they are not, we can learn to let it go by simply
            letting ourselves think that whatever we do, its regulation is not
            in our control.
          </p>
          <h2 className="my-4 mt-6 text-sub-heading font-semibold">
            Make peace with your inner critic
          </h2>
          <p>
            Blaming oneself for any kind of shortcomings comes naturally to us.
            Our inner critic tends to exercise self-blame as a coping mechanism
            but we need to understand that to err is human. We are bound to make
            mistakes and the sooner we accept that, the better our wellbeing
            will be. Accept that we will criticise ourselves from time to time
            but we also need to give ourselves a break from this, so that our
            mind can breathe!
          </p>
          <h2 className="my-4 mt-6 text-sub-heading font-semibold">
            Every problem has a silver lining
          </h2>
          <p>
            This might sound a bit unusual but every circumstance we face, or
            every situation we encounter does teach us something or the other.
            Be it learning self-control or be it practising gratitude. Instead
            of being self-criticising, try to look at what the problem is trying
            to each us because learning that would help you gain another
            essential skill that might prove useful in the long run.
          </p>
          <h2 className="my-4 mt-6 text-sub-heading font-semibold">
            Seek professional help
          </h2>
          <p>
            It is 101% acceptable to seek help for your concerns. Sometimes one
            might find it difficult to deal with their problems on their own and
            ignoring this might lead you further down the rabbit hole. In order
            to avoid this one must welcome the fact that it is okay to seek help
            as it might help you gain a new perspective and empower you to look
            at the solutions.
          </p>
          <p>
            Your thoughts are what make you. Take charge of it and encourage
            yourself to be happy in the moment!
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NegativeThinking;
