import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateToken, clearToken } from "../store/authSlice";
import { RootState, AppDispatch } from "../store";

const useTokenValidation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth?.token || null);

  useEffect(() => {
    const validateUserToken = async () => {
      if (token) {
        try {
          await dispatch(validateToken()).unwrap();
        } catch {
          dispatch(clearToken());
          navigate("/login");
        }
      }
    };

    validateUserToken();
  }, [dispatch, navigate, token]);
};

export default useTokenValidation;
