import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PostSkeleton: React.FC = () => {
  const skeletonBaseColor = "#242424";
  const skeletonHighlightColor = "#3c3c3c";

  return (
    <div className="mb-3 h-[192px] cursor-pointer rounded-xl border border-primary bg-primary p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Skeleton
            circle={true}
            height={24}
            width={24}
            className="mr-2"
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            height={8}
            width={80}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
        <div className="flex items-center">
          <Skeleton
            height={8}
            width={60}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <h2 className="text-xl font-semibold">
        <Skeleton
          height={8}
          width={`80%`}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </h2>
      <p className="text-[17px]">
        <Skeleton
          height={8}
          count={4}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </p>
    </div>
  );
};

export default PostSkeleton;
