import { HashLink } from "react-router-hash-link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareXTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface ListItem {
  label: string;
  url: string;
}

interface SocialIcon {
  icon: any;
  url: string;
}

const Footer = () => {
  const listItems: ListItem[] = [
    { label: "About us", url: "/home/#about" },
    { label: "Privacy Policy", url: "/privacy" },
    { label: "Refund Policy", url: "/refund" },
    { label: "Shipping Policy", url: "/shippingpolicy" },
    { label: "Contact us", url: "/contact" },
    { label: "Terms & Conditions", url: "/tnc" },
    { label: "Support", url: "/support" },
  ];

  const socialIcons: SocialIcon[] = [
    { icon: faYoutube, url: "https://www.youtube.com/@onelifehealth_care" },
    {
      icon: faInstagram,
      url: "https://www.instagram.com/onelifehealth_care",
    },
    {
      icon: faWhatsapp,
      url: "https://api.whatsapp.com/send?phone=918758394152",
    },
    // { icon: faFacebook, url: "/" },
    // { icon: faSquareXTwitter, url: "/" },
    {
      icon: faLinkedin,
      url: "https://www.linkedin.com/company/onelifehealthcare",
    },
    // { icon: faDiscord, url: "/" },
  ];

  return (
    <section
      id="footer"
      className="bg-white bg-opacity-50 px-8 py-5 text-center font-heading text-lg drop-shadow md:drop-shadow-none"
    >
      <ul className="flex flex-wrap items-center justify-center gap-x-4 pb-1 ">
        {listItems.map((item, index) => (
          <li className="hover:scale-105 hover:opacity-70" key={index}>
            {item.url.startsWith("/#") ? (
              <HashLink smooth to={item.url}>
                {item.label}
              </HashLink>
            ) : (
              <a href={item.url}>{item.label}</a>
            )}
          </li>
        ))}
      </ul>
      <ul className="flex items-center justify-center gap-x-4 text-xl">
        {socialIcons.map((socialIcon, index) => (
          <li className="hover:scale-105 hover:opacity-70" key={index}>
            <a href={socialIcon.url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={socialIcon.icon} />
            </a>
          </li>
        ))}
      </ul>
      <p>
        <span className="font-medium">Address:</span> 11th floor , Brigade
        Signature Towers , Kattamnallur , Bangalore 560049
      </p>
      <p className="mt-2">
        © Fly Fare Technologies LLP 2024. All rights reserved.
      </p>
    </section>
  );
};

export default Footer;
