import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { clearToken, setUsername } from "../store/authSlice";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";

interface NavItem {
  label: string;
  url: string;
}

const { REACT_APP_S3_URL, REACT_APP_API_URL } = process.env;

const Navbar = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeUrl, setActiveUrl] = useState<string>(window.location.pathname);
  const [isMobileView, setIsMobileView] = useState<boolean>(
    window.innerWidth <= 1024,
  );
  const [dropdownTimeout, setDropdownTimeout] = useState<NodeJS.Timeout | null>(
    null,
  );
  const username = useSelector((state: RootState) => state.auth.username);
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const token = localStorage.getItem("XM0CZv3HA9");
    if (token) {
      axios
        .get(`${REACT_APP_API_URL}/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.data.username) {
            dispatch(setUsername(response.data.data.username));
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("XM0CZv3HA9");
    dispatch(clearToken());
    setDropdownOpen(false);
    setMenuOpen(false);
  };

  const NavItems: NavItem[] = [
    { label: "Home", url: "/home/#home" },
    { label: "About us", url: "/home/#about" },
    { label: "Space", url: "/" },
    { label: "Blogs", url: "/blog" },
    { label: "Contact us", url: "/contact" },
    { label: "SOS", url: "" },
  ];

  useEffect(() => {
    const handleLocationChange = () => {
      const currentPath = window.location.pathname;
      const currentHash = window.location.hash;
      if (currentHash) {
        setActiveUrl(currentHash);
        const element = document.querySelector(currentHash.replace("/", ""));
        if (element) {
          const yCoordinate =
            element.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = navbarRef.current?.offsetHeight || 0;
          window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
        }
      } else if (currentPath === "/login" || currentPath === "/signup") {
        setActiveUrl(currentPath);
      } else if (currentPath === "/") {
        setActiveUrl("/#home");
        setTimeout(() => {
          const yOffset = navbarRef.current?.offsetHeight || 0;
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 0);
      } else {
        setActiveUrl(currentPath);
      }
    };

    handleLocationChange();
    window.addEventListener("hashchange", handleLocationChange);
    window.addEventListener("popstate", handleLocationChange);

    return () => {
      window.removeEventListener("hashchange", handleLocationChange);
      window.removeEventListener("popstate", handleLocationChange);
    };
  }, []);

  const handleItemClick = (url: string) => {
    if (url === "/home/#home") {
      navigate("/home");
      setActiveUrl("/home/#home");
      setTimeout(() => {
        const yOffset = navbarRef.current?.offsetHeight || 0;
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 0);
      setMenuOpen(false);
    } else if (url.startsWith("/#")) {
      setActiveUrl(url);
      setTimeout(() => {
        const element = document.querySelector(url.replace("/", ""));
        if (element) {
          const yCoordinate =
            element.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = navbarRef.current?.offsetHeight || 0;
          window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
        }
      }, 0);
      setMenuOpen(false);
    } else {
      setActiveUrl(url);
      navigate(url);
      setMenuOpen(false);
    }
  };

  const handleScroll = () => {
    setIsTop(window.pageYOffset === 0);
    const aboutSection = document.getElementById("about");
    const homeSection = document.getElementById("home");
    const scrollPosition =
      window.pageYOffset + (navbarRef.current?.offsetHeight || 0);

    if (aboutSection && homeSection) {
      const aboutSectionTop =
        aboutSection.offsetTop - (navbarRef.current?.offsetHeight || 0);
      const homeSectionTop = homeSection.offsetTop;

      if (scrollPosition >= aboutSectionTop) {
        setActiveUrl("/home/#about");
      } else if (
        scrollPosition < aboutSectionTop &&
        scrollPosition >= homeSectionTop
      ) {
        setActiveUrl("/home/#home");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [menuOpen]);

  const handleMouseEnter = () => {
    if (dropdownTimeout) {
      clearTimeout(dropdownTimeout);
      setDropdownTimeout(null);
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setDropdownOpen(false);
    }, 300);
    setDropdownTimeout(timeout);
  };

  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = navbarRef.current?.offsetHeight || 0;
    window.scrollTo({ top: yCoordinate - yOffset, behavior: "smooth" });
  };

  return (
    <div
      ref={navbarRef}
      className={`fixed z-10 w-full bg-white transition-all duration-300 lg:flex lg:items-center lg:justify-between lg:px-20 ${
        isTop ? "lg:top-5" : "top-0"
      }`}
    >
      <div className="flex items-center justify-between px-4 py-3">
        <a href="/">
          <img
            src={`${REACT_APP_S3_URL}/assets/one-life-logo.png`}
            className="h-10"
            alt="one-life-logo"
          />
        </a>
        <div className="flex items-center gap-x-2 lg:hidden">
          <a
            href="tel:+919999666555"
            className="flex items-center gap-x-2 font-semibold text-red-500"
          >
            <img
              src={`${REACT_APP_S3_URL}/assets/alarm.png`}
              alt="SOS"
              className="mb-1 h-6"
            />
            <span>SOS</span>
          </a>
          <button onClick={() => setMenuOpen(!menuOpen)}>
            <img
              src={
                menuOpen
                  ? `${REACT_APP_S3_URL}/assets/icons/cancel.png`
                  : `${REACT_APP_S3_URL}/assets/icons/menu.png`
              }
              alt={menuOpen ? "Cancel" : "Menu"}
              className={
                menuOpen
                  ? "ml-2 h-5 transition ease-in"
                  : "h-7 transition ease-in-out"
              }
            />
          </button>
        </div>
      </div>
      <ul
        className={`${
          menuOpen ? "block h-screen bg-stone-100 py-20" : "hidden"
        } gap-x-7 text-center font-heading text-xl lg:flex lg:list-none lg:items-center`}
      >
        {NavItems.map((item, index) => (
          <li
            className={`my-6 cursor-pointer text-[22px] hover:scale-105 hover:opacity-60 lg:my-0 lg:text-xl ${
              activeUrl === item.url ||
              (item.url === "/home/#home" && activeUrl === "/home/#home") ||
              (item.url === "/home/#about" && activeUrl === "/home/#about") ||
              (item.url === "/login" && activeUrl === "/login") ||
              (item.url === "/signup" && activeUrl === "/signup")
                ? "font-medium text-stone-800 underline"
                : ""
            }`}
            key={index}
            onClick={() => handleItemClick(item.url)}
          >
            {item.label === "SOS" ? (
              <a
                href="tel:+919999666555"
                className="hidden items-center gap-x-2 font-medium text-red-500 lg:flex"
              >
                <img
                  src={`${REACT_APP_S3_URL}/assets/alarm.png`}
                  alt="SOS"
                  className="mb-1 h-5"
                />
                <span>{item.label}</span>
              </a>
            ) : item.url.startsWith("/#") ? (
              <HashLink smooth to={item.url} scroll={scrollWithOffset}>
                {item.label}
              </HashLink>
            ) : (
              <a href={item.url}>{item.label}</a>
            )}
          </li>
        ))}

        {!username ? (
          <li
            className={`my-6 cursor-pointer text-[22px] hover:scale-105 hover:opacity-60 lg:my-0 lg:text-xl ${
              activeUrl === "/login" || activeUrl === "/signup"
                ? "font-medium text-stone-800 underline"
                : ""
            }`}
            onClick={() => handleItemClick("/login")}
          >
            <a href="/login">Login/Sign up</a>
          </li>
        ) : (
          <>
            {isMobileView ? (
              <>
                <li
                  className="my-6 cursor-pointer text-[22px] hover:scale-105 hover:opacity-60 lg:my-0 lg:text-xl"
                  onClick={() => handleItemClick("/profile")}
                >
                  <a href="/profile">Profile</a>
                </li>
                <li
                  className="my-6 cursor-pointer text-[22px] hover:scale-105 hover:opacity-60 lg:my-0 lg:text-xl"
                  onClick={handleLogout}
                >
                  Logout
                </li>
              </>
            ) : (
              <li
                className="relative inline-block text-left"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="flex cursor-pointer items-center"
                >
                  <FaUserCircle className="mr-2 h-6 w-6 text-[#a499e4]" />
                  {username}
                </div>
                {dropdownOpen && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-0 mt-4 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div className="py-1">
                      <a
                        href="/profile"
                        className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100"
                      >
                        Profile
                      </a>
                      <button
                        onClick={handleLogout}
                        className="block w-full px-4 py-2 text-left text-lg text-gray-700 hover:bg-gray-100"
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
