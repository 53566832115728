import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface Session {
  _id: string;
  client: {
    name: string;
    email: string;
    userId: string;
  };
  date: string;
  day: number;
  startTime: number;
  endTime: number;
  notes: string;
  status: string;
  calendarXUserId: string;
  calendarXEventId: string;
  calendarXUserAvailabilityId: string;
  meta: {
    provider: string;
    code: string | null;
  };
  createdAt: string;
  updatedAt: string;
  psychologist: {
    _id: string;
    name: string;
    image: string;
    title: string;
  };
  product: {
    _id: string;
    title: string;
    description: string;
    durationInMinutes: number;
    type: string;
  };
}

interface SessionsState {
  sessions: Session[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: SessionsState = {
  sessions: [],
  status: "idle",
  error: null,
};

export const fetchSessions = createAsyncThunk(
  "sessions/fetchSessions",
  async (statusFilter: string, { getState }) => {
    const state = getState() as { auth: { token: string } };
    const token = state.auth.token;
    const response = await axios.get(
      `${REACT_APP_API_URL}/calendar-x/my-bookings?status=${statusFilter}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data.data;
  },
);

export const cancelBooking = createAsyncThunk(
  "sessions/cancelBooking",
  async (bookingId: string, { getState }) => {
    const state = getState() as { auth: { token: string } };
    const token = state.auth.token;
    const response = await axios.post(
      `${REACT_APP_API_URL}/calendar-x/cancel-booking/${bookingId}`,
      { status: "canceled" },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  },
);

const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessions = action.payload;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch sessions";
      })
      .addCase(cancelBooking.fulfilled, (state, action) => {
        state.sessions = state.sessions.map((session) =>
          session._id === action.meta.arg
            ? { ...session, status: "canceled" }
            : session,
        );
      });
  },
});

export default sessionsSlice.reducer;
