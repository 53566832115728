import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faThumbsUp } from "@fortawesome/free-solid-svg-icons";

interface Therapist {
  name: string;
  image: string;
  title: string;
  experience: string;
  rating: number;
  languages: string[];
  sessions: {
    _id: string;
    active: string;
    description: string;
    title: string;
    pricing: {
      IN: number;
      US: number;
      discount: number;
      isFree: boolean;
    };
  }[];
}

interface TherapistCardProps {
  therapist: Therapist;
  country: string;
}

const { REACT_APP_S3_URL } = process.env;

const TherapistCard: React.FC<TherapistCardProps> = ({
  therapist,
  country,
}) => {
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="my-3 h-auto w-full rounded-xl border border-primary bg-secondary p-3 tracking-wide shadow-sm">
      <div className="flex items-start">
        <img
          src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${therapist.image}`}
          alt={therapist.name}
          className="mr-3 h-14 w-14 rounded-full"
          onError={(e) => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = `${REACT_APP_S3_URL}/uploads/psychologists/profile/default-profile.png`;
          }}
        />
        <div className="w-full">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-title font-semibold">{therapist.name}</h1>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="text-tertiary mr-1 h-3"
            />
          </div>
          <p className="text-tertiary text-[12px] font-medium">
            {therapist.title}
          </p>
          <p className="text-tertiary text-[12px]">
            {therapist.experience} Years Experience
          </p>
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between text-[11px]">
        <div className="flex items-center gap-x-1">
          <div className="mr-3 flex items-center rounded-md bg-[#999999b0] px-2 py-1 text-white">
            <FontAwesomeIcon icon={faThumbsUp} className="mr-1 h-3" />
            <p>{therapist.rating}%</p>
          </div>
          {therapist.languages.map((language, index) => (
            <span className="text-tertiary" key={index}>
              {capitalizeFirstLetter(language)}
              {index < therapist.languages.length - 1 ? " | " : ""}
            </span>
          ))}
        </div>
        <button className="bg-light rounded-md px-2 py-1 text-sm font-medium text-black">
          {country !== "IN"
            ? `$${therapist.sessions[0]?.pricing.US}`
            : `₹${therapist.sessions[0]?.pricing.IN}`}
        </button>
      </div>
    </div>
  );
};

export default TherapistCard;
