import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const { REACT_APP_S3_URL } = process.env;

const NoShame = () => {
  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-70 px-8 pb-6 pt-24 font-heading lg:px-24 lg:pb-20 lg:pt-36"
      >
        <img
          src={`${REACT_APP_S3_URL}/assets/Speaking-about-your-mental-health-is-not-shame-at-all.jpg`}
          alt="Speaking-about-your-mental-health-is-not-shame-at-all.jpg"
          className="w-full"
        />
        <h1 className="mb-4 mt-8 text-left text-2xl font-medium leading-snug sm:text-3xl lg:text-5xl lg:leading-tight">
          Speaking about mental health is not shame at all
        </h1>
        <p className="font-bold sm:text-xl">By Tanya Sharma</p>
        <p className="font-medium sm:text-lg">April 22, 2023</p>
        <div className="my-5 text-lg lg:my-10 lg:text-xl">
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            INTRODUCTION TO THE REALITY
          </h2>
          <p>
            We frequently have self-conscious thoughts and the sense that we are
            not 'normal' in accordance with societal norms. It might have to do
            with how we think, dress, act, or even who we truly are. One of
            those things is mental health, which carries with it a certain
            amount of shame. As we sit here People who have mental illnesses may
            experience stigma; they may be treated differently, seen negatively,
            and made to feel ashamed or unworthy, as though they are somehow
            inferior to other people.
          </p>
          <p className="my-3">
            This discrimination may result from stigma, which may exacerbate
            mental illness. It is an example of a stigma when someone with a
            mental illness is referred to as 'dangerous,' 'crazy,' or
            'incompetent' rather than being ill. It's time for us to recognise
            how mental health contributes to many of our physical illnesses,
            just as we are attentive to people's physical well-being.
          </p>
          <p className="my-3">
            When someone with a mental illness is made fun of or referred to as
            weak for getting care, there is also stigma. This shame frequently
            incorporates false stereotypes. It's possible to say that people
            with mental illnesses tend to be more violent than the general
            population. Anxious people may be called cowards rather than
            suffering from a disease. It is possible to advise depressed people
            to 'snap out of it.' It is inaccurate to refer to people with
            schizophrenia as having 'split personalities.' These are all
            instances of stigmatisation of those who suffer from mental illness.
          </p>
          <p className="my-3">
            Do you, however, consider it to be anything to be ashamed of? Do you
            think we should capitalise on this stigma in order to feel worse
            about ourselves? I choose to say NO.
          </p>
          <p className="my-3">
            I saw someone post that I might be just as damaged as you on
            Instagram one day as I was scrolling through it. You remember those
            'I need my shrink' memes we keep seeing? It makes me happy and proud
            that we are now having this conversation. I'm starting to normalise
            it to my routine doctor's appointments for the flu or even my
            gynecological visits.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            MENTAL HEALTH CARE - HOW DOES IT WORK?
          </h2>
          <p>
            If you've decided to discuss your mental health and mental health
            care with someone, you might be anxious about how things will go and
            what might happen let's talk about what to anticipate.
          </p>
          <p className="my-3">
            For both participants in the conversation, things could seem a
            little awkward at first. Talking about anything having to do with
            one's body or health might be difficult for many people at first.
            But You'll likely experience relief. It can seem like a weight has
            been lifted when you are able to open up and discuss something that
            you have been keeping to yourself for a long time. You never know
            what personal experience the person you're speaking to has. Also
            that you might not receive the response you were anticipating. When
            you do have the courage to speak up, hearing comments like 'you
            simply have the blues,' 'get over it,' 'stop being foolish,' or 'you
            worry too much' can be demoralising.
          </p>
          <p className="my-3">
            This kind of response might occasionally be related to culture or
            expectations. Attempt to describe how it is actually impacting your
            ability to lead a healthy and happy life and how you are unsure of
            how to make things better. Someone else will 'get it' if for some
            reason the person you picked to talk to is still not understanding.
            Consider another person you may speak with who could provide you
            with the support you require. But don't stop as it's your first step
            and we are just going to move forward with this.
          </p>
          <p className="my-3">
            Speaking openly about one's current mental state, childhood traumas,
            or challenging living situations requires a lot of effort. What can
            we do, then, to help them feel at ease in our presence?
          </p>
          <p className="my-3">
            Listen. Without interjecting, let them finish their sentences and
            their thoughts. You can answer once they've finished speaking.
            Indicate to them if you comprehend. We can also avoid responding
            with phrases like 'You're simply having a bad week' or 'I'm sure
            it's nothing ' that downplay how they are feeling or what they are
            going through. Make yourself accessible to speak once more if
            necessary. Even though it might be quite relieving for someone to
            reveal something they've been holding private, mental health issues
            rarely get resolved in a single discussion. Remind the person you
            spoke with that they can get in touch with you again if they are
            experiencing trouble. It's acceptable to inform them about specific
            times of day or days.
          </p>
          <p>
            We live in a world where both the messiness and beauty of our lives
            coexist. Let's make its beauty more beautiful for all around us.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NoShame;
