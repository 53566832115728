import LazyImage from "./LazyImage";

const { REACT_APP_S3_URL } = process.env;
interface ServiceContent {
  service: string;
  description: string;
  backgroundColor: string;
  backgroundOpacity: string;
}

const ServicesContent: ServiceContent[] = [
  {
    service: "anonymity, security and safety",
    description:
      "Your identity remains safeguarded as you open up about your deepest struggles.",
    backgroundColor: "bg-[#7F8CCB]",
    backgroundOpacity: "bg-opacity-30",
  },
  {
    service: "seasoned professionals at your fingertips",
    description:
      "Engage in insightful conversations with experienced psychologists and empathetic individuals who genuinely care.",
    backgroundColor: "bg-[#F0C4CF]",
    backgroundOpacity: "bg-opacity-30",
  },
  {
    service: "Around-the-clock support",
    description:
      "Day or night, you're never alone. Our dedicated team ensures that someone is always available to lend a compassionate ear.",
    backgroundColor: "bg-[#D0DBE0]",
    backgroundOpacity: "bg-opacity-40",
  },
  {
    service: "liberation to express",
    description:
      "Feel free to articulate your challenges without reservation, knowing that here, your voice matters.",
    backgroundColor: "bg-[#ECDDCE]",
    backgroundOpacity: "bg-opacity-40",
  },
  {
    service: "empower through support",
    description:
      "Beyond seeking solace, you also have the opportunity to extend a helping hand to others traversing similar paths.",
    backgroundColor: "bg-[#F4CB9E]",
    backgroundOpacity: "bg-opacity-30",
  },
];

const Services = () => {
  return (
    <>
      <div className="m-auto w-full px-8 pt-6 lg:flex lg:justify-between lg:pb-16 lg:pl-24 lg:pr-24 lg:pt-32">
        <LazyImage
          src={`${REACT_APP_S3_URL}/assets/its-ok-to-not-be-ok.png`}
          className="m-auto hidden h-auto text-center md:h-96  lg:ml-0 lg:block lg:h-[32.5rem]"
          alt="its-ok-not-to-be-ok"
        />
        <div className="text-center lg:mt-0 lg:text-right">
          <h1 className="font-tan-moncheri text-2xl font-semibold uppercase leading-loose tracking-wider text-stone-800 md:mb-5 md:text-2xl lg:mb-6 lg:text-6xl lg:font-normal">
            It's okay to
          </h1>
          <h1 className="font-tan-moncheri text-2xl font-semibold uppercase leading-loose tracking-wider text-stone-800 md:text-2xl lg:text-6xl lg:font-normal">
            not be okay
          </h1>
          <LazyImage
            src={`${REACT_APP_S3_URL}/assets/its-ok-to-not-be-ok.png`}
            className="m-auto h-auto text-center sm:h-[350px] md:h-96 lg:ml-0 lg:hidden lg:h-[32.5rem]"
            alt="its-ok-not-to-be-ok"
          />
          <div className="w-full pb-4 pt-6 text-center font-heading text-xl md:text-sub-heading lg:flex lg:items-center lg:justify-end lg:py-8 lg:text-right">
            <p className="m-auto w-[90%] lg:w-[32.5rem]">
              In the fast-paced hustle of life, it's easy to feel overwhelmed by
              the weight of our problems. But fret not, for here, within the
              safe confines of our anonymous haven, you can unburden your heart
              and soul without fear or judgment.
            </p>
          </div>
        </div>
      </div>
      <div className="px-8 pb-8 lg:px-0 lg:pb-16">
        {ServicesContent.map((item, index) => (
          <div
            className={`${item.backgroundColor} m-auto my-6 flex h-auto items-center justify-center pb-7 pt-5 md:w-[90%] md:py-0 lg:my-10 lg:h-40 lg:w-[70%] ${item.backgroundOpacity} text-center`}
          >
            <div>
              <h1 className="my-2 px-7 font-tan-moncheri text-base font-semibold uppercase leading-loose tracking-widest text-stone-800 lg:px-0 lg:text-xl">
                {item.service}
              </h1>
              <p className="m-auto px-6 font-heading text-xl md:w-[90%] lg:w-[70%] lg:text-sub-heading">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Services;
