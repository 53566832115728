import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface BookingDetails {
  _id: string;
  client: {
    name: string;
    email: string;
  };
  date: string;
  startTime: number;
  endTime: number;
  notes: string;
  status: string;
  psychologist: {
    _id: string;
    name: string;
    image: string;
    title: string;
  };
  product: {
    _id: string;
    title: string;
    description: string;
    durationInMinutes: number;
  };
}

interface BookingState {
  details: BookingDetails | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: BookingState = {
  details: null,
  status: "idle",
  error: null,
};

export const fetchBookingDetails = createAsyncThunk(
  "booking/fetchBookingDetails",
  async (calendarXBookingId: string, { getState }) => {
    const state = getState() as { auth: { token: string } };
    const token = state.auth.token;
    const response = await axios.get(
      `${REACT_APP_API_URL}/calendar-x/reschedule-booking/${calendarXBookingId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data.data;
  },
);

export const rescheduleBooking = createAsyncThunk(
  "booking/rescheduleBooking",
  async (
    {
      calendarXBookingId,
      client,
      notes,
      date,
      startTime,
      endTime,
    }: {
      calendarXBookingId: string;
      client: { name: string; email: string };
      notes: string;
      date: string;
      startTime: number;
      endTime: number;
    },
    { getState },
  ) => {
    const state = getState() as { auth: { token: string } };
    const token = state.auth.token;
    const response = await axios.post(
      `${REACT_APP_API_URL}/calendar-x/reschedule-booking/${calendarXBookingId}`,
      { client, notes, date, startTime, endTime },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return response.data;
  },
);

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBookingDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.details = action.payload;
      })
      .addCase(fetchBookingDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Failed to fetch booking details";
      })
      .addCase(rescheduleBooking.fulfilled, (state) => {
        state.status = "succeeded";
      });
  },
});

export default bookingSlice.reducer;
