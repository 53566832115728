import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ChatBot from "../components/ChatBot";
import LoadingScreen from "../components/LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const ShippingPolicyPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 200);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section
        id="shipping"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-28 sm:pb-20 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 mb-6 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white bg-opacity-40 p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-lg font-semibold tracking-widest text-stone-800 sm:text-3xl">
              Shipping Policy
            </h1>
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:py-6 sm:text-[20px] lg:px-24">
            <div>
              <h1 className="font-medium uppercase underline">Overview</h1>
              <p className="my-3 sm:my-5">
                One Life Health Care is a community platform dedicated to
                offering free services and the option to consult with
                professional therapists for a fee. As our offerings are entirely
                service-based, there are no physical products that require
                shipping. Therefore, this policy outlines the delivery and
                access to our services rather than the shipment of tangible
                goods.
              </p>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Service Delivery
              </h1>
              <ul className="list-decimal">
                <li className="ml-5 font-semibold">Access to Free Services</li>
                <p className="my-2">
                  <span className="font-medium">- Community Resources:</span>{" "}
                  All users have free access to our community resources,
                  including forums, articles, webinars, and support groups.
                  These resources are accessible immediately upon registration
                  and login.
                </p>
                <p className="my-2">
                  <span className="font-medium ">- No Physical Delivery:</span>{" "}
                  Since our community resources are digital, there is no need
                  for physical delivery or shipping.
                </p>
                <li className="ml-5 mt-4 font-semibold">
                  Therapist Consultations
                </li>
                <p className="my-2">
                  <span className="font-medium">- Booking a Session:</span>{" "}
                  Users can book consultations with professional therapists
                  through our platform. Upon successful booking and payment,
                  users will receive a confirmation email with details of the
                  scheduled session.
                </p>
                <p className="my-2">
                  <span className="font-medium">- Delivery Method:</span>{" "}
                  Consultations are conducted via secure video calls or phone
                  calls, based on the user’s preference. Details on how to
                  access the consultation will be provided in the confirmation
                  email.
                </p>
                <p className="my-2">
                  <span className="font-medium ">- Timeliness:</span> We strive
                  to ensure that all consultations occur at the scheduled time.
                  Users are encouraged to be ready and available at the
                  appointed time to maximize the benefit of the session.
                </p>
              </ul>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Confirmation and Notifications
              </h1>
              <ul className="list-decimal">
                <li className="ml-5 font-semibold">Access to Free Services</li>
                <p className="my-2">
                  - <span className="font-medium">Booking Confirmation:</span>{" "}
                  Upon booking a therapist consultation, users will receive an
                  email confirmation that includes the date, time, and access
                  details for the session.
                </p>
                <p className="my-2">
                  <span className="font-medium">- Reminder Notifications:</span>{" "}
                  Users will receive reminder notifications prior to their
                  scheduled consultation to ensure they are prepared for the
                  session.
                </p>
              </ul>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Rescheduling and Cancellations
              </h1>
              <ul className="list-decimal">
                <li className="ml-5 font-semibold">Rescheduling</li>
                <p className="my-2">
                  - Users may reschedule their consultations by contacting our
                  support team at least 24 hours before the scheduled session.
                  Rescheduling is subject to therapist availability.
                </p>
                <p className="my-2">
                  - Instructions for rescheduling will be included in the
                  booking confirmation email.
                </p>
                <li className="ml-5 font-semibold">Cancellations</li>
                <p className="my-2">
                  - If a user needs to cancel a scheduled consultation, they
                  must notify us at least 24 hours in advance.
                </p>
                <p className="my-2">
                  - Cancellations made less than 24 hours before the scheduled
                  session are non-refundable, in line with our refund policy.
                </p>
              </ul>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Service Issues
              </h1>
              <p className="my-2">
                <span className="font-medium ">- Technical Difficulties:</span>{" "}
                In the event of technical difficulties that prevent the
                completion of a scheduled consultation, users should contact our
                support team immediately. We will work to resolve the issue
                promptly and reschedule the session if necessary.
              </p>
              <p className="my-2">
                <span className="font-medium ">- Non-Delivery of Service:</span>{" "}
                If the promised service is not delivered as scheduled, users
                should refer to our refund policy for information on how to
                request a refund.
              </p>
              <h1 className="mb-4 mt-6 font-medium uppercase underline">
                Support
              </h1>
              <p className="my-2">
                For any questions or concerns regarding our service delivery,
                please contact our support team at support@onelifehealth.care.
                We are committed to ensuring a seamless and satisfactory
                experience for all our users.
              </p>
              <p className="my-2">
                Thank you for choosing One Life Health Care.
              </p>
              <p className="mb-1 mt-4 font-medium">Sincerely,</p>
              <p className="font-semibold">The One Life Health Care Team</p>
            </div>
          </div>
        </div>
      </section>
      <ChatBot />
      <Footer />
    </>
  );
};

export default ShippingPolicyPage;
