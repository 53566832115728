import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ChatBot from "../components/ChatBot";
import LoadingScreen from "../components/LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const SupportPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 200);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section
        id="support"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-28 sm:pb-20 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 mb-6 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white bg-opacity-40 p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-xl font-semibold tracking-widest text-stone-800 sm:text-3xl">
              Support
            </h1>{" "}
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:py-6 sm:text-[20px] lg:px-24">
            <h1 className="mb-6 text-2xl font-medium xl:text-3xl">
              Welcome to One Life Healthcare Support
            </h1>
            <div>
              <h1 className="font-medium uppercase underline">Introduction</h1>
              <p className="my-3 sm:my-5">
                At One Life Health Care, we are dedicated to providing you with
                the best possible experience on our platform. Whether you have a
                question, need assistance, or want to give feedback, we're here
                to help.
              </p>
              <h1 className="mb-2 font-medium uppercase underline">
                How Can We Help You?
              </h1>{" "}
              <ul className="list-decimal">
                <li className="ml-5 mt-6 font-semibold">General Inquiries</li>
                <p className="my-2">
                  If you have any general questions about our platform,
                  services, or community resources, please visit our FAQ section
                  for quick answers.
                </p>
                <li className="ml-5 mt-6 font-semibold">
                  Account and Registration
                </li>
                <p className="my-2">
                  <span className="font-medium">Creating an Account: </span> For
                  help with setting up your account, please refer to our Account
                  Setup Guide.
                </p>
                <p className="my-2">
                  <span className="font-medium">Managing Your Account: </span>{" "}
                  Learn how to update your profile, change your password, and
                  manage your settings in our Account Management Guide.
                </p>
                <li className="ml-5 mt-6 font-semibold">
                  Therapist Consultations
                </li>{" "}
                <p className="my-2">
                  <span className="font-medium"> Booking a Session: </span> For
                  assistance with booking a therapist consultation, visit our
                  Booking Guide.
                </p>
                <p className="my-2">
                  <span className="font-medium">
                    Rescheduling or Canceling:
                  </span>
                  If you need to reschedule or cancel a session, please refer to
                  our Rescheduling and Cancellation Policy.
                </p>
                <li className="ml-5 mt-6 font-semibold">Community Forum</li>{" "}
                <p className="my-2">
                  <span className="font-medium"> Accessing the Forum: </span> If
                  you're having trouble accessing our community forum, please
                  see our Forum Access Guide.
                </p>
                <p className="my-2">
                  <span className="font-medium">
                    {" "}
                    Posting and Participation:{" "}
                  </span>{" "}
                  For tips on how to engage in discussions and post content,
                  check out our Community Participation Guide.
                </p>
                <li className="ml-5 mt-6 font-semibold">
                  Technical Support
                </li>{" "}
                <p className="my-2">
                  <span className="font-medium"> Common Issues:</span> Find
                  solutions to common technical problems in our Technical
                  Support FAQ
                </p>
                <p className="my-2">
                  <span className="font-medium">
                    {" "}
                    Contact Technical Support:
                  </span>{" "}
                  If you’re experiencing technical difficulties, please contact
                  our technical support team at{" "}
                  <span className="font-medium">
                    support@onelifehealth.care
                  </span>{" "}
                </p>
                <p className="mt-6 font-medium uppercase underline">
                  Contact Us
                </p>{" "}
                <p className="my-3">
                  If you can’t find the answer you’re looking for, our support
                  team is here to assist you.
                </p>{" "}
                <p className="my-2">
                  <span className="font-medium"> Email Support:</span> Reach out
                  to us at{" "}
                  <span className="font-medium">
                    support@onelifehealth.care
                  </span>{" "}
                  . We strive to respond to all inquiries within 24 hours.
                </p>
                <p className="my-2">
                  <span className="font-medium"> Live Chat:</span> Use our live
                  chat feature available from 9 am to 9 pm IST, 7 days a week,
                  for real-time assistance.
                </p>
                <p className="my-2">
                  <span className="font-medium">Community Support:</span> Engage
                  with our community in the forum where you can ask questions
                  and get support from other members.
                </p>
                <p className="my-2">
                  <span className="font-medium"> Feedback:</span> Your feedback
                  is invaluable to us. If you have suggestions or comments on
                  how we can improve our platform, please fill out our Feedback
                  Form. We appreciate your input and are always looking for ways
                  to enhance your experience.
                </p>
                <p className="mt-6 font-medium uppercase underline">
                  Privacy and Security
                </p>{" "}
                <p className="my-2">
                  For information on how we handle your personal data, please
                  visit our Privacy Policy. If you have any concerns about your
                  data security, contact us at{" "}
                  <span className="font-medium">
                    support@onelifehealth.care
                  </span>{" "}
                  .
                </p>
                <p className="mt-6 font-medium uppercase underline">
                  Emergency Support
                </p>{" "}
                <p className="my-2">
                  Please note that One Life Health Care is not a crisis service.
                  If you are experiencing a mental health emergency, please
                  contact your local emergency services or reach out to a crisis
                  hotline in your area immediately. Or else click on the SOS
                  button
                </p>
              </ul>
            </div>
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:text-[20px] lg:px-24">
            <div>
              <p className="my-2">
                Thank you for being a part of the One Life Health Care
                community. We're here to support you every step of the way.
              </p>
              <p className="mb-1 mt-4 font-medium">Sincerely,</p>
              <p className="font-semibold">The One Life Health Care Team</p>
            </div>
          </div>
        </div>
      </section>
      <ChatBot />
      <Footer />
    </>
  );
};

export default SupportPage;
