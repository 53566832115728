import React from "react";
import { paymentImageUrls } from "../constants/cardImageUrls";
interface PaymentCardProps {
  detail: any;
}

const PaymentCard: React.FC<PaymentCardProps> = ({ detail }) => {
  return (
    <div className="mb-4 rounded-lg border bg-white p-4 shadow-sm">
      <p className="pb-1.5 text-sm text-gray-600">
        <strong>Date:</strong> {new Date(detail.createdAt).toLocaleDateString()}
      </p>
      <p className="pb-1.5 text-sm text-gray-600">
        <strong>Title:</strong> {detail.product.title}
      </p>
      <p className="pb-1.5 text-sm text-gray-600">
        <strong>Description:</strong> {detail.product.description}
      </p>
      <p className="pb-1.5 text-sm text-gray-600">
        <strong>Psychologist Name:</strong> {detail.psychologist.name}
      </p>
      <p className="pb-1.5 text-sm text-gray-600">
        <strong>Amount:</strong>
        {detail.payment.currency === "INR"
          ? `₹${detail.payment.amount / 100}`
          : `$${detail.payment.amount / 100}`}
      </p>
      <p className="pb-1.5 text-sm text-gray-600">
        <strong>Payment Status:</strong>
        {detail.payment.paid ? (
          <span className="rounded bg-green-100 px-2 py-1 text-green-700">
            Paid
          </span>
        ) : (
          <span className="rounded bg-red-100 px-2 py-1 text-red-500">
            Failed
          </span>
        )}
      </p>
      <div className="flex items-center pb-1.5 text-sm text-gray-600">
        <strong>Payment Method:</strong>
        {detail.payment.method === "card" ? (
          <>
            <img
              src={paymentImageUrls[detail.payment.card.network.toLowerCase()]}
              alt={detail.payment.card.network}
              className={`ml-2 ${detail.payment.card.network.toLowerCase() === "bajaj" || detail.payment.card.network.toLowerCase() === "rupay" ? "h-8 w-16" : "h-6 w-9"}`}
            />
            <span className="ml-2">●●●● {detail.payment.card.last4}</span>
          </>
        ) : detail.payment.method in paymentImageUrls ? (
          <>
            <img
              src={paymentImageUrls[detail.payment.method]}
              alt={detail.payment.method}
              className="ml-2 h-6 w-6"
            />
            <span className="ml-2">
              {detail.payment.method === "upi"
                ? "UPI"
                : detail.payment.method.charAt(0).toUpperCase() +
                  detail.payment.method.slice(1)}
            </span>
          </>
        ) : (
          "nil"
        )}
      </div>
    </div>
  );
};

export default PaymentCard;
