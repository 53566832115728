import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const { REACT_APP_S3_URL } = process.env;

const SocialMedia = () => {
  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-70 px-8 pb-6 pt-24 font-heading lg:px-24 lg:pb-20 lg:pt-36"
      >
        <img
          src={`${REACT_APP_S3_URL}/assets/The-role-of-social-media-in-mental-health-care.jpg`}
          alt="The-role-of-social-media-in-mental-health-care.jpg"
          className="w-full"
        />
        <h1 className="mb-4 mt-8 text-left text-2xl font-medium leading-snug sm:text-3xl lg:text-5xl lg:leading-tight">
          The role of social media in mental health: Exploring the relationship
          between social media use and mental health
        </h1>
        <p className="font-bold sm:text-xl">By Shruti Padhye</p>
        <p className="font-medium sm:text-lg">September 14, 2023</p>
        <div className="my-5 text-lg lg:my-10 lg:text-xl">
          <p>
            In today's interconnected world, social media has become an integral
            part of our daily lives. From sharing photos and updates to
            connecting with friends and acquaintances, social media platforms
            offer numerous benefits. However, it is essential to recognise that
            our engagement with these digital platforms can also influence our
            mental well-being . In this blog, we will explore the effects of
            social media on mental health, shed light on both the positive and
            negative aspects, and provide guidance on maintaining a healthy
            relationship with social media.
          </p>
          <p className="my-3">
            Social media has revolutionised the way we communicate, connect, and
            consume information. While it offers numerous advantages, its impact
            on mental health has been a subject of concern and scrutiny.
            Understanding the effects of social media on mental health is
            essential to navigate these digital platforms mindfully.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            LET'S DELVE INTO THE VARIOUS WAYS SOCIAL MEDIA CAN INFLUENCE OUR
            MENTAL WELL-BEING:
          </h2>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            1. SOCIAL COMPARISON AND SELF-ESTEEM:
          </h2>
          <p>
            Social media often presents an idealised version of people's lives,
            showcasing their successes, achievements, and positive experiences.
            This constant exposure to carefully curated content can lead to
            social comparison, where individuals assess themselves in relation
            to others. As a result, feelings of inadequacy, envy, and low
            self-esteem may arise if users perceive themselves as falling short
            in comparison to their online peers. This phenomenon can be
            particularly damaging to vulnerable individuals, leading to anxiety,
            depression, and a diminished sense of self-worth.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            2. FEAR OF MISSING OUT (FOMO):
          </h2>
          <p>
            The fear of missing out is a pervasive feeling triggered by social
            media updates of friends and acquaintances engaged in exciting
            activities or events. Scrolling through feeds filled with seemingly
            fun-filled gatherings and adventures can leave users feeling left
            out, lonely, or disconnected. Constant exposure to FOMO can lead to
            a sense of inadequacy, anxiety, and an increased need to stay
            continuously connected to social media to avoid missing anything.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            3. CYBERBULLYING AND ONLINE HARASSMENT:
          </h2>
          <p>
            Social media platforms, while facilitating positive interactions,
            can also become breeding grounds for cyberbullying and online
            harassment. The anonymity and distance provided by these platforms
            can embolden individuals to engage in hurtful behavior, leading to
            emotional distress, anxiety, and, in severe cases, depression or
            suicidal thoughts . Victims of cyberbullying may experience a
            significant decline in self-esteem and social withdrawal, further
            exacerbating mental health challenges.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            4. SLEEP DISRUPTION:
          </h2>
          <p>
            Excessive social media use , especially before bedtime, can disrupt
            sleep patterns and negatively impact sleep quality. The constant
            stimulation, exposure to blue light emitted by screens, and
            emotional responses to social media content can interfere with the
            ability to relax and unwind, leading to sleep disturbances . Poor
            sleep quality can contribute to mood swings, irritability, and
            decreased cognitive function, affecting overall mental well-being.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            5. ADDICTION AND DOPAMINE RESPONSE:
          </h2>
          <p>
            Social media platforms are designed to be engaging and addictive,
            often triggering a dopamine response in the brain when users receive
            likes, comments, or other forms of validation. This reinforcement
            mechanism can lead to addictive behaviors, where individuals find
            themselves constantly seeking approval and validation from their
            online presence. Addiction to social media can lead to neglect of
            real-life responsibilities, social isolation , and feelings of
            withdrawal, which can have adverse effects on mental health.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            6. PROMOTION OF DISORDERED EATING AND BODY IMAGE CONCERNS:
          </h2>
          <p>
            Social media can also contribute to the promotion of unrealistic
            beauty standards and body image ideals. Exposure to images of
            seemingly perfect bodies can lead to body dissatisfaction, eating
            disorders , and body dysmorphia. These unrealistic expectations can
            have a negative impact on self-esteem, leading to anxiety,
            depression, and a preoccupation with appearance.
          </p>
          <p className="my-3">
            While social media offers numerous benefits, it is essential to
            acknowledge and address its potential effects on mental health.
            Being mindful of the content we consume, setting boundaries for
            social media use, and nurturing real-life connections are essential
            strategies to protect our mental well-being. Additionally, promoting
            positive mental health awareness, encouraging empathy and kindness
            online, and fostering a supportive digital environment can help
            mitigate the negative impact of social media on mental health. By
            being aware of the potential risks and adopting healthy social media
            habits, we can create a more balanced and positive digital
            experience for ourselves and others.
          </p>
          <p className="my-3">
            Social media has become an influential force in our lives, shaping
            the way we connect and perceive the world. While it offers benefits
            such as enhanced social connections and increased mental health
            awareness, it also presents challenges like social comparison and
            cyberbullying. By maintaining a healthy relationship with social
            media, setting boundaries, and prioritizing our well-being, we can
            navigate the digital landscape while safeguarding our mental
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            MAINTAINING A HEALTHY RELATIONSHIP WITH SOCIAL MEDIA:
          </h2>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            1. SET BOUNDARIES:
          </h2>
          <p>
            Establish boundaries for your social media use. Determine specific
            times of the day when you engage with social media and limit the
            overall time spent on these platforms. Prioritise offline activities
            and face-to-face interactions.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            2. CURATE YOUR FEED:
          </h2>
          <p>
            Be mindful of who you follow and the content you consume. Unfollow
            accounts that make you feel inadequate or anxious. Instead, follow
            accounts that inspire, educate, and promote positive mental Health.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            3. PRACTICE DIGITAL DETOX:
          </h2>
          <p>
            Take regular breaks from social media to recharge and refocus.
            Consider having designated periods, such as weekends or evenings,
            where you disconnect entirely from social media and engage in
            activities that bring you joy and relaxation.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            4. CULTIVATE REAL-LIFE CONNECTIONS:
          </h2>
          <p>
            Remember to prioritise in-person interactions and nurture
            relationships offline. Engage in activities that allow you to
            connect with others on a deeper level and foster a sense of
            belonging.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            5. SEEK SUPPORT:
          </h2>
          <p>
            If you find that social media is negatively impacting your mental
            health, don't hesitate to seek support . Reach out to friends,
            family, or mental health professionals who can provide guidance and
            assistance.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SocialMedia;
