import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ChatBot from "../components/ChatBot";
import LoadingScreen from "../components/LoadingScreen";

const { REACT_APP_S3_URL } = process.env;

const TncPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 200);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <section
        id="tnc"
        className="h-auto min-h-screen w-full bg-white bg-opacity-80 pb-12 pt-28 sm:pb-20 sm:pt-40"
      >
        <div
          className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}
        >
          <div className="mx-8 mb-6 flex items-center justify-start gap-x-6 font-tan-moncheri uppercase sm:mx-0 sm:justify-center sm:gap-x-8">
            <img
              src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
              alt="go-back"
              className="mb-2 h-8 cursor-pointer rounded-full bg-white bg-opacity-40 p-2 hover:scale-105 sm:h-12 sm:p-3"
              onClick={handleGoBack}
            />
            <h1 className="text-lg font-semibold tracking-widest text-stone-800 sm:text-3xl">
              Terms and Conditions
            </h1>
          </div>
          <div className="m-auto px-8 font-heading text-lg sm:py-6 sm:text-[20px] lg:px-24">
            <div>
              <ul className="list-decimal">
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Introduction
                </li>
                <p className="my-2">
                  Welcome to One Life Health Care. By accessing or using our
                  platform, you agree to comply with and be bound by the
                  following terms and conditions. Please read them carefully
                  before using our services. If you do not agree with any part
                  of these terms and conditions, you must not use our platform.
                </p>
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Services
                </li>
                <p className="my-2">
                  One Life Health Care is a community platform that provides
                  free resources and the option to consult with professional
                  therapists for a fee. Our services include, but are not
                  limited to, access to community forums, articles, webinars,
                  support groups, and one-on-one therapy sessions.
                </p>
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  User Registration
                </li>
                <p className="my-2">
                  To access certain features of our platform, users may need to
                  register and create an account. Users agree to provide
                  accurate and complete information during the registration
                  process and to update such information as needed.
                </p>
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Use of Services
                </li>
                <p className="my-2">
                  <span className="font-medium ">- Community Resources:</span>{" "}
                  Users can freely access community resources such as forums,
                  articles, and webinars. Users must use these resources
                  responsibly and respectfully.
                </p>
                <p className="my-2">
                  <span className="font-medium ">
                    - Therapist Consultations:
                  </span>{" "}
                  Users can book and pay for consultations with therapists. All
                  consultations are conducted via secure video or phone calls.
                </p>
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  User Conduct
                </li>
                <p className="my-2">
                  Users agree to use the platform in accordance with all
                  applicable laws and regulations. Prohibited conduct includes,
                  but is not limited to:
                </p>
                <p className="my-2">
                  - Posting or transmitting any content that is unlawful,
                  harmful, threatening, abusive, harassing, defamatory, vulgar,
                  obscene, or otherwise objectionable.
                </p>
                <p className="my-2">
                  - Impersonating any person or entity or misrepresenting your
                  affiliation with a person or entity. Engaging in any activity
                  that disrupts or interferes with the functioning of the
                  platform.
                </p>
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Payment and Refund Policy
                </li>
                <p className="my-2">
                  <span className="font-medium">- Payments:</span> Users must
                  pay for therapist consultations at the time of booking. All
                  payments are processed through our secure payment gateway.
                </p>
                <p className="my-2">
                  <span className="font-medium">- Refunds:</span> Our standard
                  policy is that payments for consultations are non-refundable.
                  Exceptions are made if the service promised was not delivered,
                  in which case users must request a refund within 24 hours of
                  the scheduled session. Refer to our Refund Policy for detailed
                  information.
                </p>{" "}
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Rescheduling and Cancellations
                </li>{" "}
                <p className="my-2">
                  <span className="font-medium">- Rescheduling:</span> Users can
                  reschedule consultations by contacting our support team at
                  least 24 hours in advance. Rescheduling is subject to
                  therapist availability.
                </p>
                <p className="my-2">
                  <span className="font-medium">- Cancellations:</span> Users
                  must cancel consultations at least 24 hours in advance to
                  avoid charges. Cancellations made less than 24 hours before
                  the scheduled session are non-refundable.
                </p>{" "}
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Privacy Policy
                </li>{" "}
                <p className="my-2">
                  We are committed to protecting your privacy. Please review our
                  Privacy Policy for details on how we collect, use, and protect
                  your personal information.
                </p>
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Intellectual Property
                </li>{" "}
                <p className="my-2">
                  All content on the platform, including text, graphics, logos,
                  images, and software, is the property of One Life Health Care
                  or its content suppliers and is protected by intellectual
                  property laws. Users may not reproduce, distribute, or create
                  derivative works from any content on the platform without
                  express written permission.
                </p>{" "}
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Disclaimers and Limitation of Liability
                </li>
                <p className="my-2">
                  <span className="font-medium">- Disclaimers:</span> Our
                  platform and services are provided on an "as is" basis without
                  warranties of any kind, either express or implied. We do not
                  warrant that the platform will be uninterrupted or error-free.
                </p>
                <p className="my-2">
                  <span className="font-medium">
                    - Limitation of Liability:
                  </span>{" "}
                  In no event shall One Life Health Care be liable for any
                  direct, indirect, incidental, special, or consequential
                  damages arising out of or in connection with the use of our
                  platform or services.
                </p>{" "}
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Changes to Terms and Conditions
                </li>{" "}
                <p className="my-2">
                  We reserve the right to modify these terms and conditions at
                  any time. Changes will be effective immediately upon posting
                  on the platform. Your continued use of the platform
                  constitutes your acceptance of the revised terms and
                  conditions.
                </p>{" "}
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Governing Law
                </li>{" "}
                <p className="my-2">
                  These terms and conditions are governed by and construed in
                  accordance with the laws of [Your Jurisdiction]. Any disputes
                  arising out of or relating to these terms and conditions shall
                  be resolved in the courts of Bangalore.
                </p>{" "}
                <li className="ml-5 mt-6 font-medium uppercase underline">
                  Contact Us
                </li>{" "}
                <p className="my-3">
                  If you have any questions or concerns about these terms and
                  conditions, please contact us at{" "}
                  <span className="font-medium">
                    support@onelifehealth.care
                  </span>{" "}
                  .
                </p>
              </ul>
              <p className="my-2">Thank you for using One Life Health Care.</p>
              <p className="mb-1 mt-4 font-medium">Sincerely,</p>
              <p className="font-semibold">The One Life Health Care Team</p>
            </div>
          </div>
        </div>
      </section>
      <ChatBot />
      <Footer />
    </>
  );
};

export default TncPage;
