import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchSessions, cancelBooking } from "../store/sessionsSlice";
import { ClipLoader } from "react-spinners";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const { REACT_APP_S3_URL } = process.env;

const Sessions: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth?.token || null);
  const sessions = useSelector((state: RootState) => state.sessions.sessions);
  const status = useSelector((state: RootState) => state.sessions.status);
  const error = useSelector((state: RootState) => state.sessions.error);
  const [filter, setFilter] = useState<string>("confirmed");
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const [showReschedulePopup, setShowReschedulePopup] =
    useState<boolean>(false);
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(
    null,
  );
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null,
  );
  const [selectedCalendarXEventId, setSelectedCalendarXEventId] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (token) {
      dispatch(fetchSessions(filter));
    }
  }, [filter, token, dispatch]);

  if (status === "loading") {
    return (
      <div className="mt-20 flex h-full items-center justify-center xl:relative xl:bottom-40 xl:mt-0">
        <ClipLoader size={50} color={"#ffffff"} loading={true} />
      </div>
    );
  }

  if (status === "failed") {
    return <div className="text-center text-base text-light">{error}</div>;
  }

  const convertMinutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12;

    return `${adjustedHours}:${mins.toString().padStart(2, "0")}${period}`;
  };

  const formatDateTime = (date: string, startTime: number, endTime: number) => {
    console.log(date, startTime, endTime);

    const formattedDate = format(new Date(date), "EEEE, MMMM d, yyyy");
    const formattedStartTime = convertMinutesToTime(startTime);
    const formattedEndTime = convertMinutesToTime(endTime);

    return `${formattedDate} · ${formattedStartTime} - ${formattedEndTime}`;
  };

  const handleCancelBooking = (bookingId: string) => {
    dispatch(cancelBooking(bookingId)).then(() => {
      toast.success("Booking cancelled successfully!");
      setShowConfirmPopup(false);
      setSelectedBookingId(null);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };

  const openConfirmPopup = (
    bookingId: string,
    productId: string,
    calendarXEventId: string,
  ) => {
    setSelectedBookingId(bookingId);
    setSelectedProductId(productId);
    setSelectedCalendarXEventId(calendarXEventId);
    setShowConfirmPopup(true);
  };

  const closeConfirmPopup = () => {
    setShowConfirmPopup(false);
    setSelectedBookingId(null);
  };

  const openReschedulePopup = (
    bookingId: string,
    productId: string,
    calendarXEventId: string,
  ) => {
    setSelectedBookingId(bookingId);
    setSelectedProductId(productId);
    setSelectedCalendarXEventId(calendarXEventId);
    setShowReschedulePopup(true);
  };

  const handleReschedule = () => {
    navigate(
      `/reschedule/${selectedProductId}/${selectedCalendarXEventId}/${selectedBookingId}`,
    );
  };

  return (
    <div className="hide-scrollbar h-[70%] bg-primary px-4 pt-4 xl:overflow-y-scroll xl:pb-32">
      <div className="mb-4 flex w-full justify-end text-sm xl:space-x-3">
        <select
          className="rounded-lg bg-light px-2 py-3 text-base text-primary hover:bg-gray-300 xl:p-2 xl:text-sm"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        >
          <option value="confirmed">Confirmed</option>
          <option value="pending">Pending</option>
          <option value="canceled">Canceled</option>
          <option value="request-reschedule">Request Reschedule</option>
        </select>
      </div>
      {sessions.length === 0 ? (
        <div className="text-center text-[15px] text-light xl:mt-5">
          No sessions found.
        </div>
      ) : (
        sessions.map((session) => (
          <div
            key={session._id}
            className="mb-3 rounded-xl border border-primary bg-primary p-4 shadow-md"
          >
            <div className="flex items-center gap-x-4">
              <img
                src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${session.psychologist.image}`}
                alt={session.psychologist.name}
                className="h-12 w-12 rounded-full"
              />
              <div>
                <h2 className="text-[17px] font-medium text-title">
                  {session.psychologist.name}
                </h2>
                <p className="text-[15px] text-tertiary">
                  {session.psychologist.title}
                </p>
              </div>
            </div>
            <p className="mt-4 text-[15px] text-tertiary">
              {session.product.title}
            </p>
            <p className="mt-1 text-[15px] text-tertiary">
              {formatDateTime(session.date, session.startTime, session.endTime)}
            </p>
            {filter === "confirmed" && (
              <>
                <p className="mt-2 flex items-center gap-x-1 text-[15px] text-tertiary">
                  <img
                    src={`${REACT_APP_S3_URL}/assets/icons/google-meet.png`}
                    alt={session.psychologist.name}
                    className="h-5 w-5 rounded-full"
                  />{" "}
                  {session.meta.provider.charAt(0).toUpperCase() +
                    session.meta.provider.slice(1)}
                </p>
                {session.meta.code && (
                  <p className="text-[15px] text-tertiary">
                    <a
                      href={`https://meet.google.com/${session.meta.code}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      Click here to join
                    </a>
                  </p>
                )}
              </>
            )}
            <div className="mt-4 flex justify-end space-x-2">
              {filter !== "canceled" && (
                <>
                  <button
                    className="rounded bg-light px-3 py-1 font-medium text-primary"
                    onClick={(e) =>
                      openReschedulePopup(
                        session._id,
                        session.product._id,
                        session.calendarXEventId,
                      )
                    }
                  >
                    Reschedule
                  </button>
                  <button
                    className="rounded bg-secondary px-3 py-1 font-medium text-light"
                    onClick={() =>
                      openConfirmPopup(
                        session._id,
                        session.product._id,
                        session.calendarXEventId,
                      )
                    }
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        ))
      )}

      {showConfirmPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="w-full max-w-sm rounded-lg bg-primary p-6 text-light shadow-lg">
            <h2 className="mb-4 text-lg font-semibold">Confirm Cancellation</h2>
            <p className="mb-4">
              Are you sure you want to cancel this session?
            </p>
            <div className="flex flex-col space-y-2 sm:flex-row sm:justify-end sm:space-x-4 sm:space-y-0">
              <button
                className="rounded bg-secondary px-4 py-2 text-light"
                onClick={closeConfirmPopup}
              >
                Cancel
              </button>
              <button
                className="rounded bg-light px-4 py-2 text-primary"
                onClick={() => handleCancelBooking(selectedBookingId!)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showReschedulePopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="w-full max-w-sm rounded-lg bg-primary p-6 text-light shadow-lg">
            <h2 className="mb-4 text-lg font-semibold">Confirm Reschedule</h2>
            <p className="mb-4">
              Are you sure you want to reschedule this session?
            </p>
            <div className="flex flex-col space-y-2 sm:flex-row sm:justify-end sm:space-x-4 sm:space-y-0">
              <button
                className="rounded bg-secondary px-4 py-2 text-light"
                onClick={() => setShowReschedulePopup(false)}
              >
                Cancel
              </button>
              <button
                className="rounded bg-light px-4 py-2 text-primary"
                onClick={handleReschedule}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sessions;
