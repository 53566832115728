import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface PasswordInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  required?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  onChange,
  error,
  required = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-2">
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          className="h-12 w-full rounded-md border border-[#d896a5] px-4 text-lg text-black outline-none"
          placeholder="Password"
          value={value}
          onChange={onChange}
          required={required}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex items-center pr-5 text-sm leading-5 focus:outline-none"
          style={{ top: "50%", transform: "translateY(-50%)" }}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>
      {error && <p className="mt-1 text-base text-red-500">{error}</p>}
    </div>
  );
};

export default PasswordInput;
