export const generateSlug = (title: string) => {
  // Normalize the string to NFKD form to separate characters from their diacritical marks
  const normalizedTitle = title.normalize('NFKD');

  // Remove non-ASCII characters and diacritical marks
  const asciiTitle = normalizedTitle.replace(/[^\w\s-]/g, '');

  // Replace spaces and hyphens with a single hyphen
  const hyphenatedTitle = asciiTitle.trim().replace(/[\s_-]+/g, '-').toLowerCase();

  return hyphenatedTitle;
}