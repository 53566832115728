import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.state || !location.state.fromFeed) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.pathname, location.state]);

  return null;
};

export default ScrollToTop;
