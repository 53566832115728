import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { createPost, updatePost } from "../api/postsAPI";
import "../styles/CreatePost.css";

interface CreatePostProps {
  onPostCreated: (postId: string) => void;
  onPostUpdated?: () => void;
  postToEdit?: Post | null;
  onClose: () => void;
}

interface Post {
  _id: string;
  title: string;
  body: string;
  tags: string[];
}

const CreatePost: React.FC<CreatePostProps> = ({
  onPostCreated,
  onPostUpdated,
  postToEdit,
  onClose,
}) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector((state: RootState) => state.auth?.token || null);
  const tags = [
    "Confession",
    "Question",
    "Self Help",
    "Query",
    "Distress",
    "Daily Challenge",
  ];

  useEffect(() => {
    if (postToEdit) {
      setTitle(postToEdit.title);
      setBody(postToEdit.body);
      const formattedTags = postToEdit.tags.map((tag) =>
        tag
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      );
      setSelectedTags(formattedTags);
    }
  }, [postToEdit]);

  const handleTagClick = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
    );
  };

  const handleClear = () => {
    setTitle("");
    setBody("");
    setSelectedTags([]);
  };

  const handleCreatePost = async () => {
    if (!token || loading) {
      return;
    }

    setLoading(true);

    const postData = {
      title,
      body,
      tags: selectedTags.map((tag) =>
        tag.trim().toLowerCase().replace(" ", "-"),
      ),
    };

    try {
      const response = await createPost(token, postData);
      toast.success("Post added");
      setTimeout(() => {
        onPostCreated(response.data._id);
        onClose();
      }, 1500);
    } catch (error) {
      console.error("Error creating post:", error);
      toast.error("Failed to add post. Please try again later.");
      setLoading(false);
    }
  };

  const handleUpdatePost = async () => {
    if (!token || !postToEdit || loading) {
      return;
    }

    setLoading(true);

    const postData = {
      title,
      body,
      tags: selectedTags,
    };

    try {
      const response = await updatePost(token, postToEdit._id, postData);
      setTimeout(() => {
        if (onPostUpdated) {
          onPostUpdated();
        }
        onClose();
      }, 1500);
      toast.success("Post updated");
    } catch (error) {
      console.error("Error updating post:", error);
      toast.error("Failed to update post. Please try again later.");
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full flex-col rounded-lg bg-primary p-4 font-community text-base">
      <h1 className="mb-6 text-xl font-semibold text-light">
        {postToEdit ? "Edit Post" : "Create a Post"}
      </h1>
      <div className="mb-4">
        <input
          type="text"
          id="title"
          name="title"
          placeholder="Title"
          className="title h-12 w-full rounded-lg border border-primary bg-secondary p-3 font-medium text-light placeholder-primary"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={loading}
        />
      </div>
      <div className="mb-4">
        <textarea
          id="body"
          name="body"
          placeholder="Body"
          className="body-box h-60 w-full rounded-lg border border-primary bg-secondary p-3 text-sm text-light placeholder-primary"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          disabled={loading}
        ></textarea>
      </div>
      <div className="mb-4">
        <p className="mb-2 text-base font-medium text-light">Tags:</p>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span
              key={index}
              className={`cursor-pointer rounded-full border border-primary px-4 py-2 text-center text-[13px] ${
                selectedTags.includes(tag)
                  ? "bg-white-light text-black"
                  : "bg-dark text-[#fcfcfc] hover:bg-[#eee8e12a]"
              }`}
              onClick={() => !loading && handleTagClick(tag)}
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
      <div className="mt-4 flex justify-end space-x-4 text-[15px]">
        <button
          className="rounded-full border border-primary px-6 py-2 text-light hover:bg-opacity-70"
          onClick={() => {
            handleClear();
            onClose();
          }}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className={`rounded-full border border-primary px-6 py-2 text-primary ${
            loading
              ? "disabled-button bg-gray-400"
              : "bg-light hover:bg-opacity-70"
          }`}
          onClick={() => {
            if (!loading) {
              postToEdit ? handleUpdatePost() : handleCreatePost();
            }
          }}
          disabled={loading}
        >
          {postToEdit ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
};

export default CreatePost;
