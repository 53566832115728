import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

const { REACT_APP_API_URL } = process.env;

interface Purchase {
  _id: string;
  title: string;
  description: string;
  psychologistName: string;
  psychologistImage: string;
  psychologistTitle: string;
  noOfSessions: number;
  paymentMethod: string;
  price: number;
  date: string;
  currency: string;
  cardLast4?: string;
  discount: number;
  calendarXEventId: string;
  remainingNoOfSessions: number;
}

interface PurchasesState {
  data: Purchase[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PurchasesState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchPurchases = createAsyncThunk(
  "purchases/fetchPurchases",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/available-sessions`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data.data.map((session: any) => ({
        _id: session.product._id,
        title: session.product.title,
        description: session.product.description,
        psychologistName: session.psychologist.name,
        psychologistImage: session.psychologist.image,
        psychologistTitle: session.psychologist.title,
        noOfSessions: session.product.noOfSessions,
        remainingNoOfSessions: session.remainingNoOfSessions,
        paymentMethod: session.payment.method,
        price: session.payment.amount,
        date: session.createdAt,
        currency: session.payment.currency,
        cardLast4: session.payment.card?.last4,
        discount: session.product.pricing.discount,
        calendarXEventId: session.product.calendarXEventId,
      }));
    } catch (error) {
      return rejectWithValue("Failed to fetch purchases");
    }
  },
);

const purchasesSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchases.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPurchases.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchPurchases.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default purchasesSlice.reducer;
