import { useEffect, useState } from "react";
import Home from "../components/Home";
import Services from "../components/Services";
import AboutUs from "../components/AboutUs";
import Banner from "../components/Banner";
import ChatBot from "../components/ChatBot";
import useTokenValidation from "../hooks/useTokenValidation";
import LoadingScreen from "../components/LoadingScreen";

const LandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useTokenValidation();

  useEffect(() => {
    setShowContent(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}>
      <Home />
      <Services />
      <AboutUs />
      <Banner />
      <ChatBot />
    </div>
  );
};

export default LandingPage;
