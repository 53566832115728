import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const { REACT_APP_S3_URL } = process.env;

const MentalHealthWorkspace = () => {
  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-70 px-8 pb-6 pt-24 font-heading lg:px-24 lg:pb-20 lg:pt-36"
      >
        <img
          src={`${REACT_APP_S3_URL}/assets/Mental-health-in-the-workplace.jpg`}
          alt="Mental-health-in-the-workplace"
          className="w-full"
        />
        <h1 className="mb-4 mt-8 text-left text-2xl font-medium leading-snug sm:text-3xl lg:text-5xl lg:leading-tight">
          Mental Health in The Workplace
        </h1>
        <p className="font-bold sm:text-xl">By Shruti Padhye</p>
        <p className="font-medium sm:text-lg">September 18, 2023</p>
        <div className="my-5 text-lg lg:my-10 lg:text-xl">
          <p>
            Mental health in the workplace refers to an employee's emotional,
            psychological, and social well-being within their work environment.
            It encompasses factors such as stress management, work-life balance,
            job satisfaction, and the overall work culture. Neglecting mental
            health in the workplace can lead to severe consequences, both for
            employees and organizations.
          </p>
          <p className="my-3">
            In recent years, the awareness of mental health and its impact on
            overall well-being has grown significantly. One crucial area where
            mental health plays a pivotal role is the workplace. As employees
            spend a substantial portion of their lives at work, it is imperative
            for organizations to prioritize mental health care in the workplace.
          </p>
          <p className="my-3">
            In this blog, we will explore the importance of workplace mental
            health, its impact on employees, and the best mental health services
            available in India to support a mentally healthy workforce.
          </p>
          <p className="my-3">
            According to a study by the National Institute of Mental Health and
            Neurosciences (NIMHANS) in India, approximately 42.5% of employees
            in the corporate sector suffer from depression or general anxiety
            disorder due to work-related stress.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            DECREASED JOB SATISFACTION:
          </h2>
          <p>
            A study published in the Indian Journal of Occupational and
            Environmental Medicine revealed that poor mental health can lead to
            decreased job satisfaction, contributing to higher turnover rates
            and reduced employee retention.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            REDUCED PRODUCTIVITY:
          </h2>
          <p>
            A study conducted by Deloitte India reported that mental health
            issues lead to a loss of productivity costing Indian businesses
            around $1.97 billion annually.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            INCREASED ABSENTEEISM:
          </h2>
          <p className="my-3">
            The World Health Organization (WHO) states that depression and
            anxiety disorders contribute significantly to absenteeism, leading
            to a decrease in workdays and an increase in costs for businesses.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            STIGMA AND BARRIERS
          </h2>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            FEAR OF STIGMATIZATION:
          </h2>
          <p className="my-3">
            The Lancet Psychiatry Commission highlighted that stigma related to
            mental health is widespread in workplaces, causing employees to
            avoid seeking help for fear of being judged or perceived as weak.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            UNDERREPORTING OF MENTAL HEALTH ISSUES:
          </h2>
          <p className="my-3">
            According to the National Mental Health Survey of India,
            approximately 80% of people with mental health issues do not seek
            professional help due to various barriers, including stigma.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            EMPLOYEE ASSISTANCE PROGRAMS (EAPS):
          </h2>
          <p className="my-3">
            EAPs have shown significant benefits in reducing stress and
            improving mental health. A study published in the Indian Journal of
            Psychiatry demonstrated that implementing EAPs in the workplace
            resulted in reduced absenteeism and increased employee productivity.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            TELEMEDICINE AND ONLINE THERAPY:
          </h2>
          <p className="my-3">
            With the advent of technology, telemedicine and online therapy
            platforms have become viable options for employees seeking mental
            health support . A study published in the Journal of Telemedicine
            and Telecare found that telemedicine interventions were effective in
            reducing symptoms of depression and anxiety. Promoting Workplace
            Mental Health
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            TRAINING FOR MANAGERS:
          </h2>
          <p className="my-3">
            A research paper published in the Journal of Occupational and
            Environmental Medicine highlighted the importance of training
            managers to identify signs of mental health issues and provide
            appropriate support to employees.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            PROMOTING WORK-LIFE BALANCE:
          </h2>
          <p className="my-3">
            Research from the International Journal of Environmental Research
            and Public Health suggests that organizations that support work-life
            balance experience higher employee satisfaction, reduced stress
            levels, and increased productivity.
          </p>
          <p className="my-3">
            Statistics and research evidence reinforce the critical role of
            mental health care in the workplace. Work-related stress, decreased
            job satisfaction, and reduced productivity due to mental health
            issues are significant challenges that employers must address
            proactively. By implementing evidence-based mental health services,
            raising awareness, and creating a supportive work culture,
            businesses in India can foster a mentally healthy workforce that
            thrives both professionally and personally. Remember, promoting
            mental health is not just an ethical responsibility; it is an
            investment in the well-being and success of the entire organization.
            Together, let us build a workplace that prioritizes mental health
            and empowers employees to reach their full potential.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            THE SIGNIFICANCE OF MENTAL HEALTH IN THE WORKPLACE
          </h2>
          <p className="my-3">
            Mental health in the workplace refers to an employee's emotional,
            psychological, and social well-being within their work environment.
            It encompasses factors such and social well-being within their work
            environment. It encompasses factors such as stress management,
            work-life balance, job satisfaction, and the overall work culture.
            Neglecting mental health in the workplace can lead to severe
            consequences, both for employees and organizations.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            IMPACT ON EMPLOYEE WELLBEING:
          </h2>
          <p className="my-3">
            Stress and mental health issues can significantly affect an
            employee's productivity, job satisfaction, and physical health. A
            workplace that fosters mental well-being can lead to reduced
            absenteeism, increased job engagement, and a positive work
            environment.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            IMPACT ON ORGANIZATIONAL PRODUCTIVITY:
          </h2>
          <p className="my-3">
            A workforce dealing with unaddressed mental health issues is likely
            to face decreased productivity, increased errors, and a higher
            turnover rate. In contrast, promoting mental health care can boost
            employee morale, foster creativity, and enhance overall
            organizational performance.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            STIGMA AND BARRIERS:
          </h2>
          <p className="my-3">
            Unfortunately, the stigma surrounding mental health can be a
            significant barrier to seeking help for employees. Employers must
            work to create an open and accepting environment that encourages
            employees to discuss mental health issues without fear of judgment.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            BEST MENTAL HEALTH SERVICES IN INDIA
          </h2>
          <p className="my-3">
            Recognizing the significance of mental health in the workplace,
            numerous organizations in India have started offering specialized
            mental health services. These services cater to both individual
            employees and the overall workforce. Here are some of the best
            mental health services available in India:
          </p>
          <ul className="list-disc">
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                Employee Assistance Programs (EAPs):
              </span>{" "}
              EAPs provide confidential counseling and support services to
              employees dealing with personal or work-related challenges. These
              programs often include counseling sessions, workshops, and
              resources to help employees cope with stress and other mental
              health issues.
            </li>
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                Telemedicine and Online Therapy:
              </span>{" "}
              Telemedicine platforms and online therapy services have become
              increasingly popular, providing employees with easy access to
              mental health professionals from the comfort of their homes. These
              services are particularly beneficial for remote workers and
              employees in need of immediate support.
            </li>
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                Corporate Wellness Workshops:
              </span>{" "}
              Organizations can conduct regular mental health workshops and
              seminars to educate employees about stress management,
              mindfulness, and maintaining a healthy work-life balance. These
              workshops can contribute to a more informed and mentally resilient
              workforce.
            </li>
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                In-house Counselors or Therapists:
              </span>{" "}
              Larger organizations may consider having in-house mental health
              professionals who can provide ongoing support and counseling to
              employees facing mental health challenges.
            </li>
          </ul>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            NOW LET’S DISCUSS THE WAYS FOR PROMOTING WORKPLACE MENTAL HEALTH
          </h2>
          <p className="my-3">
            Creating a mentally healthy workplace requires a collective effort
            from both employers and employees. Here are some steps organizations
            can take to promote mental health in the workplace:
          </p>
          <ul className="list-disc">
            <li className="ml-4">
              {" "}
              <span className="font-medium">Raise Awareness:</span> Educate
              employees about the importance of mental health and the available
              resources. Normalizing conversations around mental health can
              encourage employees to seek help when needed.
            </li>
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                Encourage Work-Life Balance:
              </span>{" "}
              Support a healthy work-life balance by offering flexible work
              arrangements and encouraging employees to take breaks and
              vacations when needed.
            </li>
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                Provide Training for Managers:
              </span>{" "}
              Train managers to recognize signs of mental health struggles in
              their team members and create an environment where employees feel
              comfortable discussing their concerns.
            </li>
            <li className="ml-4">
              {" "}
              <span className="font-medium">
                Foster a Supportive Culture:
              </span>{" "}
              Encourage a supportive and inclusive work culture, where employees
              feel valued, respected, and supported by their colleagues and
              supervisors.
            </li>
          </ul>
          <p className="my-3">
            Prioritizing mental health in the workplace is not just a
            compassionate choice; it is also a strategic one. A mentally healthy
            workforce is more engaged, productive, and resilient, which
            ultimately benefits the organisation.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MentalHealthWorkspace;
