import React from "react";
import Navbar from "./Navbar";
import LazyImage from "../components/LazyImage";

const { REACT_APP_S3_URL } = process.env;

const Home: React.FC = () => {
  return (
    <div id="home" className="home relative w-full lg:h-[98vh]">
      <div className="lg:py-6">
        <Navbar />
      </div>
      <div className="px-8 pb-12 pt-28 lg:flex lg:px-28 lg:py-28">
        <div>
          <h1 className="heading text-center font-tan-moncheri text-2xl font-semibold uppercase leading-relaxed tracking-wider text-stone-800 lg:text-left lg:text-6xl lg:font-normal lg:text-stone-800">
            your mental
            <br />
            health matters
          </h1>
          <p className="py-4 text-center font-heading text-xl lg:max-w-[40%] lg:text-left lg:text-sub-heading">
            Explore a compassionate space where you can connect, share, and grow
            with others on their mental health journey.
          </p>
          <a href="/">
            <button className="m-auto my-5 flex w-auto items-center justify-center rounded-full bg-white px-6 py-3 font-heading text-lg font-medium uppercase tracking-wide shadow-md hover:scale-105 lg:ml-0">
              Speak it out{" "}
              <img
                src={`${REACT_APP_S3_URL}/assets/icons/thin-arrow.png`}
                alt="go-back"
                className="ml-2 h-5 rotate-180 transform cursor-pointer rounded-full bg-white bg-opacity-40 hover:scale-105"
              />
            </button>
          </a>
        </div>
        <div className="flex items-center justify-center lg:block">
          <LazyImage
            src={`${REACT_APP_S3_URL}/assets/home-flower.png`}
            className="m-auto h-auto sm:h-[350px] lg:bottom-0 lg:-mr-10 lg:-mt-14 lg:h-[600px] lg:w-[680px] 3xl:h-[600px] 3xl:w-[560px]"
            alt="flower"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
