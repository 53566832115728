import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import {
  setToken,
  setProfile,
  setLoading,
  setError,
  setUsername,
} from "../store/authSlice";
import Navbar from "../components/Navbar";
import LoadingScreen from "../components/LoadingScreen";
import FormInput from "../components/FormInput";
import PasswordInput from "../components/PasswordInput";
import { signup, login, fetchProfile } from "../api/authAPI";
import LazyImage from "../components/LazyImage";
import axios from "axios";
import "../styles/FormLoading.css";

const { REACT_APP_S3_URL } = process.env;

const LoginSignupPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isSignup = location.pathname === "/signup";
  const [username, setUsernameInput] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const backendError = useSelector((state: RootState) => state.auth.error);
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
    backend: "",
  });

  useEffect(() => {
    setShowContent(true);
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    dispatch(setError(null));
    setErrors({
      username: "",
      email: "",
      password: "",
      backend: "",
    });
    setUsernameInput("");
    setEmail("");
    setPassword("");
  }, [location.pathname, dispatch]);

  if (pageLoading) {
    return <LoadingScreen />;
  }

  const usernamePattern = /^[a-z0-9_-]+$/i;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordPattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const validateForm = () => {
    const newErrors = {
      username: "",
      email: "",
      password: "",
      backend: "",
    };
    let isValid = true;

    if (username.length < 8 || !username.match(usernamePattern)) {
      newErrors.username =
        "Username should be at least 8 characters and contain only letters, numbers, hyphens, and underscores.";
      isValid = false;
    }

    if (isSignup && (!email.match(emailPattern) || email === "")) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!isSignup && password.length < 1) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    if (isSignup && !password.match(passwordPattern)) {
      newErrors.password =
        "Password should be at least 8 characters long and include letters, numbers, and special characters.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      dispatch(setLoading(true));
      const payload = { username, password } as any;
      if (isSignup) {
        payload.email = email;
      }

      const response = isSignup ? await signup(payload) : await login(payload);
      const profileResponse = await fetchProfile(response.data.accessToken);

      dispatch(setToken(response.data.accessToken));
      dispatch(setProfile(profileResponse.data));
      dispatch(setUsername(username));
      dispatch(setLoading(false));

      setUsernameInput("");
      setEmail("");
      setPassword("");
      navigate("/");
    } catch (error: any) {
      dispatch(setLoading(false));
      if (axios.isAxiosError(error) && error.response && error.response.data) {
        dispatch(
          setError(
            isSignup
              ? error.response.data.message
              : "Invalid username or password",
          ),
        );
      } else {
        dispatch(setError("An error occurred. Please try again."));
      }
    }
  };

  return (
    <div className="h-auto min-h-screen bg-white xl:overflow-hidden">
      <div className={`content-wrapper ${showContent ? "animate-fadeIn" : ""}`}>
        <Navbar />
        <div className="flex h-auto min-h-screen items-center bg-[#fff3f5]">
          <div className="flex h-auto min-h-screen w-full flex-col items-center lg:flex-row">
            <div className="relative flex h-[50%] w-full items-end justify-center lg:right-6 lg:h-full lg:w-[50%]">
              <LazyImage
                src={`${REACT_APP_S3_URL}/assets/zen.jpg`}
                alt="zen"
                className="mt-32 h-[280px] lg:mt-0 lg:h-[550px]"
              />
            </div>
            <div className="flex w-full items-center justify-center bg-[#ffd5df] px-6 pb-8 font-heading lg:h-screen lg:w-[60%] lg:p-0 lg:pb-0">
              <div className="w-full lg:w-[50%]">
                <h1 className="m-auto mb-6 mt-5 text-center text-xl font-medium leading-relaxed lg:mt-20 lg:text-2xl">
                  Self-Care comes first. Begin Your Journey with One Life
                  Healthcare!
                </h1>
                <form onSubmit={handleSubmit}>
                  <FormInput
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => {
                      setUsernameInput(e.target.value.toLowerCase());
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        username: "",
                        backend: "",
                      }));
                      dispatch(setError(null));
                    }}
                    error={errors.username}
                    required
                  />

                  {isSignup && (
                    <FormInput
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.toLowerCase());
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          email: "",
                          backend: "",
                        }));
                        dispatch(setError(null));
                      }}
                      error={errors.email}
                      required
                    />
                  )}

                  <PasswordInput
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: "",
                        backend: "",
                      }));
                      dispatch(setError(null));
                    }}
                    error={errors.password}
                    required
                  />

                  {!isSignup && (
                    <div className="flex justify-end">
                      <span className="cursor-pointer text-lg text-gray-800 underline hover:underline hover:opacity-80 lg:text-base">
                        Forgot Password?
                      </span>
                    </div>
                  )}
                  {errors.backend || backendError ? (
                    <p className="mb-1 text-base text-red-500">
                      {errors.backend || backendError}
                    </p>
                  ) : null}
                  <button
                    type="submit"
                    className={`mt-2 h-12 w-full rounded-md text-lg ${loading ? "cursor-not-allowed bg-[#ca8696a8]" : "bg-[#ca8696] text-white"}`}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="dot-wave">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : isSignup ? (
                      "Sign Up"
                    ) : (
                      "Log In"
                    )}
                  </button>
                </form>
                <p className="my-4 text-center text-lg">
                  {isSignup ? (
                    <span
                      onClick={() => navigate("/login")}
                      className="cursor-pointer text-lg text-gray-800 hover:underline"
                    >
                      Already have an account? Log In
                    </span>
                  ) : (
                    <span
                      onClick={() => navigate("/signup")}
                      className="cursor-pointer text-lg text-gray-800 hover:underline"
                    >
                      Don't have an account? Sign Up
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupPage;
