import Footer from "./Footer";
import LazyImage from "./LazyImage";

const { REACT_APP_S3_URL } = process.env;

const Banner = () => {
  return (
    <div id="banner">
      <div className="footer-banner pb-8 pt-12 lg:px-0 lg:py-0">
        <div className="items-center justify-between lg:flex lg:pl-28">
          <div className="w-fit">
            <h1 className="quote px-8 font-tan-moncheri text-xl font-semibold uppercase tracking-widest text-stone-700 lg:my-2 lg:w-[90%] lg:px-0 lg:text-3xl lg:font-medium">
              “Recovery is not one and done. It's a lifelong journey that takes
              place one day, one step at a time.”
            </h1>
            <p className="mt-6 px-8 text-right font-heading text-xl lg:my-6 lg:px-0 lg:text-2xl">
              - Steve Jobs, Mental Health & Adaptations.
            </p>
          </div>
          <LazyImage
            src={`${REACT_APP_S3_URL}/assets/banner-image.png`}
            className="pl-8 sm:h-[350px] lg:w-[450px] lg:pl-0"
            alt="join-us"
          />
        </div>
        <p className="px-8 py-4 text-center font-heading text-xl leading-snug lg:px-0 lg:pb-20 lg:pt-5 lg:text-sub-heading">
          Join us on this journey of resilience and renewal, as together, we
          weave a tapestry of support and <br /> compassion. At One Life
          Healthcare, your well-being is not just our priority; it's our
          collective mission.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Banner;
