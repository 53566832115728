import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader: React.FC = () => {
  const skeletonBaseColor = "#242424";
  const skeletonHighlightColor = "#3c3c3c";

  return (
    <div className="mb-3 h-auto min-h-screen cursor-pointer bg-primary p-4 xl:h-screen">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Skeleton
            circle={true}
            height={24}
            width={24}
            className="mr-2"
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            height={8}
            width={80}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
        <div className="flex items-center">
          <Skeleton
            height={8}
            width={60}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <h2 className="text-xl font-semibold">
        <Skeleton
          height={8}
          width={`80%`}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </h2>
      <p className="text-[17px]">
        <Skeleton
          height={8}
          count={10}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </p>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Skeleton
            height={16}
            width={160}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
        <div className="flex items-center">
          <Skeleton
            height={16}
            width={160}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <h2 className="my-2 text-xl font-semibold">
        <Skeleton
          height={24}
          width={`100%`}
          count={1}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </h2>
      <h2 className="text-xl font-semibold">
        <Skeleton
          height={8}
          width={`100%`}
          count={20}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </h2>
    </div>
  );
};

export default SkeletonLoader;
