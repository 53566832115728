import axios from "axios";

const { REACT_APP_API_URL } = process.env;

interface PostData {
  title: string;
  body: string;
  tags: string[];
}

export const fetchPost = async (token: string, postId: string) => {
  const response = await axios.get(`${REACT_APP_API_URL}/post/${postId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const createPost = async (token: string, postData: PostData) => {
  const response = await axios.post(`${REACT_APP_API_URL}/post`, postData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const updatePost = async (
  token: string,
  postId: string,
  postData: PostData,
) => {
  const response = await axios.patch(
    `${REACT_APP_API_URL}/post/${postId}`,
    postData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};
