import React from "react";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const LoadingScreen: React.FC = () => {
  const location = useLocation();

  const isLightRoute =
    location.pathname.startsWith("/home") ||
    location.pathname.startsWith("/blog") ||
    location.pathname.startsWith("/contact") ||
    location.pathname.startsWith("/login");

  const backgroundColor = isLightRoute ? "#fbf3f5" : "#1a1a1a";
  const clipperColor = isLightRoute ? "#da9e99" : "#d6d6d6";

  return (
    <div
      className="flex h-screen items-center justify-center"
      style={{ backgroundColor }}
    >
      <ClipLoader color={clipperColor} size={50} />
    </div>
  );
};

export default LoadingScreen;
