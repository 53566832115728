import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  parseISO,
} from "date-fns";

export const formatRelativeTime = (date: string): string => {
  if (!date) {
    return "Unknown time";
  }

  try {
    const parsedDate = parseISO(date);
    const now = new Date();
    const minutesDifference = differenceInMinutes(now, parsedDate);
    const hoursDifference = differenceInHours(now, parsedDate);
    const daysDifference = differenceInDays(now, parsedDate);

    if (minutesDifference < 60) {
      return `${minutesDifference} minute${minutesDifference > 1 ? "s" : ""} ago`;
    } else if (hoursDifference < 24) {
      return `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""} ago`;
    } else {
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""} ago`;
    }
  } catch (error) {
    console.error("Error parsing date:", error);
    return "Unknown time";
  }
};
