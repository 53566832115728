import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

const { REACT_APP_API_URL } = process.env;

interface PaymentHistoryState {
  data: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PaymentHistoryState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchPaymentHistory = createAsyncThunk(
  "paymentHistory/fetchPaymentHistory",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/payments-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch payment history");
    }
  },
);

const paymentHistorySlice = createSlice({
  name: "paymentHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchPaymentHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export default paymentHistorySlice.reducer;
