import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

const { REACT_APP_API_URL } = process.env;

interface Slot {
  time: string;
}

interface Event {
  _id: string;
  title: string;
  description: string;
  durationInMinutes: number;
  provider: string;
  availability: {
    timezone: string;
  };
  psychologist: {
    _id: string;
    name: string;
    image: string;
    title: string;
  };
}

interface BookingDetails {
  sessionTitle: string;
  date: string;
  startTime: string;
  endTime: string;
  timezone: string;
  hostName: string;
  hostEmail: string;
  clientName: string;
  clientEmail: string;
  notes: string;
}

interface SlotsState {
  slots: { [date: string]: Slot[] };
  event: Event | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  bookingStatus: "idle" | "loading" | "succeeded" | "failed";
  bookingError: string | null;
  bookingDetails: BookingDetails | null;
}

const initialState: SlotsState = {
  slots: {},
  event: null,
  status: "idle",
  error: null,
  bookingStatus: "idle",
  bookingError: null,
  bookingDetails: null,
};

export const fetchSlots = createAsyncThunk(
  "slots/fetchSlots",
  async (
    {
      productId,
      calendarXEventId,
      yearMonth,
    }: { productId: string; calendarXEventId: string; yearMonth: string },
    { getState, rejectWithValue },
  ) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/calendar-x/${productId}/${calendarXEventId}?yearMonth=${yearMonth}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch slots");
    }
  },
);

export const fetchSlotsForReschedule = createAsyncThunk(
  "slots/fetchSlotsForReschedule",
  async (
    {
      calendarXBookingId,
      yearMonth,
    }: { calendarXBookingId: string; yearMonth: string },
    { getState, rejectWithValue },
  ) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/calendar-x/reschedule-booking/${calendarXBookingId}?yearMonth=${yearMonth}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch slots for rescheduling");
    }
  },
);

export const fetchBookingDetails = createAsyncThunk(
  "slots/fetchBookingDetails",
  async (calendarXBookingId: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/calendar-x/booking/${calendarXBookingId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to fetch booking details");
    }
  },
);

export const bookSession = createAsyncThunk(
  "slots/bookSession",
  async (
    {
      productId,
      calendarXEventId,
      client,
      notes,
      date,
      startTime,
      endTime,
    }: {
      productId: string;
      calendarXEventId: string;
      client: { name: string; email: string };
      notes: string;
      date: string;
      startTime: number;
      endTime: number;
    },
    { getState, rejectWithValue },
  ) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/calendar-x/book-session/${productId}/${calendarXEventId}`,
        {
          client,
          notes,
          date,
          startTime,
          endTime,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to book session");
    }
  },
);

export const rescheduleBookedSession = createAsyncThunk(
  "slots/rescheduleBookedSession",
  async (
    {
      calendarXBookingId,
      client,
      notes,
      date,
      startTime,
      endTime,
    }: {
      calendarXBookingId: string;
      client: { name: string; email: string };
      notes: string;
      date: string;
      startTime: number;
      endTime: number;
    },
    { getState, rejectWithValue },
  ) => {
    const state = getState() as RootState;
    const token = state.auth.token;

    if (!token) {
      return rejectWithValue("No token found");
    }

    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/calendar-x/reschedule-booking/${calendarXBookingId}`,
        {
          client,
          notes,
          date,
          startTime,
          endTime,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue("Failed to book session");
    }
  },
);

const slotsSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSlots.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSlots.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.slots = action.payload.data.slots;
        state.event = action.payload.data.event;
      })
      .addCase(fetchSlots.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      // fetchReschedule
      .addCase(fetchSlotsForReschedule.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSlotsForReschedule.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.slots = action.payload.data.slots;
        state.event = action.payload.data.event;
      })
      .addCase(fetchSlotsForReschedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchBookingDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBookingDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.event = action.payload.data.event;
        state.bookingDetails = action.payload.data.bookingDetails;
      })
      .addCase(fetchBookingDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(bookSession.pending, (state) => {
        state.bookingStatus = "loading";
      })
      .addCase(bookSession.fulfilled, (state, action) => {
        state.bookingStatus = "succeeded";
        state.bookingDetails = action.payload.data;
      })
      .addCase(bookSession.rejected, (state, action) => {
        state.bookingStatus = "failed";
        state.bookingError = action.payload as string;
      })
      // rescheduleSession
      .addCase(rescheduleBookedSession.pending, (state) => {
        state.bookingStatus = "loading";
      })
      .addCase(rescheduleBookedSession.fulfilled, (state, action) => {
        state.bookingStatus = "succeeded";
        state.bookingDetails = action.payload.data;
      })
      .addCase(rescheduleBookedSession.rejected, (state, action) => {
        state.bookingStatus = "failed";
        state.bookingError = action.payload as string;
      });
  },
});

export default slotsSlice.reducer;
