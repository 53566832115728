import React, { useRef } from "react";

interface BottomModalProps {
  isOpen: boolean;
  onClose: () => void;
  onLogin: () => void;
}
const { REACT_APP_S3_URL } = process.env;

const BottomModal: React.FC<BottomModalProps> = ({
  isOpen,
  onClose,
  onLogin,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="text-light absolute bottom-0 w-full rounded-t-2xl bg-secondary px-6 py-4"
      >
        <div
          className="flex w-full items-center justify-start gap-x-3 text-left"
          onClick={onLogin}
        >
          <img
            src={`${REACT_APP_S3_URL}/assets/icons/enter.png`}
            className="h-5"
            alt="login"
          />
          <button className="text-light rounded-lg py-2">
            Log In / Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default BottomModal;
