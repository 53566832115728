import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPurchases } from "../store/purchasesSlice";
import { RootState, AppDispatch } from "../store";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

const { REACT_APP_S3_URL } = process.env;

const Purchases: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    data: purchases,
    status,
    error,
  } = useSelector((state: RootState) => state.purchases);
  const token = useSelector((state: RootState) => state.auth?.token || null);

  useEffect(() => {
    if (token) {
      dispatch(fetchPurchases());
    }
  }, [dispatch, token]);

  if (status === "loading") {
    return (
      <div className="mt-20 flex h-full items-center justify-center xl:relative xl:bottom-40 xl:mt-0">
        <ClipLoader size={50} color={"#ffffff"} loading={true} />
      </div>
    );
  }

  if (status === "failed") {
    return <div className="text-center text-base text-light">{error}</div>;
  }

  const handleScheduleClick = (productId: string, calendarXEventId: string) => {
    navigate(`/schedule/${productId}/${calendarXEventId}`);
  };

  return (
    <div className="hide-scrollbar h-[70%] bg-primary px-4 pt-4 xl:overflow-y-scroll xl:pb-32">
      {purchases.length === 0 ? (
        <div className="text-center text-[15px] text-light xl:mt-5">
          No purchases yet.
        </div>
      ) : (
        purchases.map((purchase) => (
          <div
            key={purchase._id}
            className="mb-3 rounded-xl border border-primary bg-secondary p-4 shadow-md"
          >
            <div className="flex items-center gap-x-4">
              <img
                src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${purchase.psychologistImage}`}
                alt={purchase.psychologistName}
                className="h-12 w-12 rounded-full"
              />
              <div>
                <h2 className="text-[17px] font-medium text-title">
                  {purchase.psychologistName}
                </h2>
                <p className="text-[15px] text-tertiary">
                  {purchase.psychologistTitle}
                </p>
              </div>
            </div>
            <div className="my-2 border-b border-primary"></div>
            <h2 className="text-lg font-medium text-light xl:text-[17px]">
              {purchase.title}
            </h2>
            <p className="my-1 text-[15px] text-gray-300 xl:text-sm">
              {purchase.description}
            </p>
            <div className="items-center justify-between text-sm text-gray-300 xl:flex">
              <div className="flex w-full items-center text-gray-300">
                <span className="mr-2 text-[15px] text-light xl:text-[15px]">
                  Remaining Sessions:{" "}
                </span>{" "}
                {purchase.remainingNoOfSessions}
              </div>
              <div className="flex w-full justify-end">
                <button
                  className="mt-4 rounded bg-light px-4 py-2 font-medium text-primary hover:opacity-80"
                  onClick={() =>
                    handleScheduleClick(purchase._id, purchase.calendarXEventId)
                  }
                >
                  Schedule Appointment
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Purchases;
