import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import TherapistCard from "./TherapistCard";
import "../styles/Therapists.css";
import TherapistCardSkeleton from "./TherapistCardSkeleton";
import { useMediaQuery } from "react-responsive";

interface ICertificate {
  name: string;
  url: string;
}

interface Therapist {
  name: string;
  image: string;
  title: string;
  experience: string;
  rating: number;
  languages: string[];
  bio: string;
  expertise: string[];
  certificates: ICertificate[];
  sessions: {
    _id: string;
    active: string;
    description: string;
    title: string;
    pricing: {
      IN: number;
      US: number;
      discount: number;
      isFree: boolean;
    };
    noOfSessions: number;
    type: string;
  }[];
  country: string;
}

const { REACT_APP_S3_URL, REACT_APP_API_URL, REACT_APP_RZP_ID_KEY } =
  process.env;

const TherapistSidebar = () => {
  const navigate = useNavigate();
  const [therapistsData, setTherapistsData] = useState<Therapist[]>([]);
  const [country, setCountry] = useState<string>("");
  const [selectedTherapist, setSelectedTherapist] = useState<Therapist | null>(
    null,
  );
  const [sessionSelected, setSessionSelected] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.auth?.token || null);
  const email = useSelector((state: RootState) => state.auth.profile?.email);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isXLScreen = useMediaQuery({ query: "(min-width: 1280px)" });

  useEffect(() => {
    const fetchTherapists = async () => {
      try {
        const countryResponse = await axios.get(`${REACT_APP_API_URL}/country`);
        console.log(countryResponse?.data?.data);
        const response = await axios.get(`${REACT_APP_API_URL}/psychologists`);
        setCountry(countryResponse?.data?.data || "US");
        setTherapistsData(response.data.data);
      } catch (error) {
        console.error("Error fetching therapists data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTherapists();
  }, []);

  const handleTherapistClick = (therapist: Therapist) => {
    setSelectedTherapist(therapist);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleClose = () => {
    setSelectedTherapist(null);
    setSessionSelected(null);
  };

  const checkoutAmt = (
    originalAmountWithoutDiscount: number,
    discountPercentage: number,
  ) => {
    const discount = parseFloat(
      (originalAmountWithoutDiscount * (discountPercentage / 100)).toFixed(2),
    );
    return originalAmountWithoutDiscount - discount;
  };

  const createOrder = async (
    productId: string,
    checkoutType: string,
    country: string,
    couponCode: string,
  ) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/v1/payments/checkout`,
        {
          productId,
          checkoutType,
          country,
          couponCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.message || "Failed while checkout...");
      console.error("Error creating order:", error.response.data.message);
    }
  };

  const handleBuyNow = async (params: {
    productId: string;
    checkoutType: string;
    couponCode: string;
    email: string;
    sessionType: string;
  }) => {
    if (!token) {
      toast.error("Please sign in to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      return;
    }

    try {
      const { checkoutType, couponCode, productId, email, sessionType } =
        params;
      const countryResponse = await axios.get(`${REACT_APP_API_URL}/country`);
      const country = countryResponse?.data?.data || "US";
      const order = await createOrder(
        productId,
        checkoutType,
        country,
        couponCode,
      );

      const options = {
        key: REACT_APP_RZP_ID_KEY,
        amount: order.amount,
        currency: order.currency,
        name: "One Life Healthcare",
        description: `${checkoutType}:${sessionType} - onelifehealth.care - rzp`,
        order_id: order.orderId,
        handler: function (response: any) {
          toast.success(`Payment successful!`);
          setSelectedTherapist(null);
          setTimeout(() => {
            navigate("/profile", { state: { activeTab: "purchases" } });
            window.location.reload();
          }, 1000);
        },
        prefill: {
          email,
          contact: "",
        },
        notes: {
          productId: order.notes.productId,
          userId: order.notes.userId,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", function (response: any) {
        alert(`Payment failed. Reason: ${response.error.description}`);
      });
      rzp1.open();
    } catch (error) {
      console.log("Error");
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollIntervalRef.current = setInterval(() => {
        if (
          scrollContainer.scrollTop + scrollContainer.clientHeight >=
          scrollContainer.scrollHeight
        ) {
          scrollContainer.scrollTop = 0;
        } else {
          scrollContainer.scrollTop += 1;
        }
      }, 30);
    }

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [therapistsData]);

  const handleMouseEnter = () => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }
  };

  const handleMouseLeave = () => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollIntervalRef.current = setInterval(() => {
        if (
          scrollContainer.scrollTop + scrollContainer.clientHeight >=
          scrollContainer.scrollHeight
        ) {
          scrollContainer.scrollTop = 0;
        } else {
          scrollContainer.scrollTop += 1;
        }
      }, 30);
    }
  };

  return (
    <div className="therapist-sidebar relative h-auto min-h-screen overflow-hidden rounded-xl border-primary bg-primary px-4 font-community tracking-wide shadow xl:h-screen xl:w-[35%] xl:border xl:p-4 xl:pb-28">
      <div className="flex items-center justify-start pb-2 text-white xl:hidden xl:py-5">
        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className="cursor-pointer pl-5 pr-3 "
          onClick={() => navigate("/")}
        />
        <h2 className="text-lg font-semibold tracking-wide">Counsellors</h2>
      </div>
      <p className="mb-1 hidden justify-center text-center text-lg font-semibold uppercase text-light xl:flex xl:text-center">
        Counsellors
      </p>
      <div
        className="therapist-list hide-scrollbar"
        ref={scrollRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {loading
          ? Array.from({ length: 5 }).map((_, index) => (
              <TherapistCardSkeleton key={index} />
            ))
          : therapistsData.map((therapist: Therapist, index: number) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => handleTherapistClick(therapist)}
              >
                <TherapistCard therapist={therapist} country={country} />
              </div>
            ))}
      </div>
      {selectedTherapist && (
        <>
          <div className="therapist-overlay fixed inset-0 bg-black opacity-50"></div>
          <div className="therapist-details fixed bottom-4 left-1/2 max-h-[75vh] w-[95%] -translate-x-1/2 transform overflow-y-auto rounded-lg bg-primary px-4 xl:max-h-[80vh] xl:w-[75%] xl:overflow-visible xl:px-16">
            <div>
              <button
                className="close-btn absolute right-0 top-0 mr-4 mt-2 text-light"
                onClick={handleClose}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  className="h-6 w-6 xl:h-5 xl:w-5"
                />
              </button>
              <img
                src={`${REACT_APP_S3_URL}/uploads/psychologists/profile/${selectedTherapist.image}`}
                alt={selectedTherapist.name}
                className="relative mx-auto my-5 h-20 w-20 rounded-full xl:bottom-12 xl:my-2 xl:-mb-8 xl:h-24 xl:w-24"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = `${REACT_APP_S3_URL}/uploads/psychologists/profile/default-profile.png`;
                }}
              />
              <div>
                <div className="flex items-center justify-center gap-x-2">
                  <h2 className="text-lg font-semibold text-title xl:text-xl">
                    {selectedTherapist.name}
                  </h2>
                  <img
                    src={`${REACT_APP_S3_URL}/assets/verified.png`}
                    alt="verification-tick"
                    className="h-5"
                  />
                </div>
                <div className="flex flex-wrap items-center justify-center gap-x-2 text-[15px] text-tertiary">
                  <p className="text-center font-medium">
                    {selectedTherapist.title}
                  </p>
                  |
                  <p className="text-center font-medium">
                    {selectedTherapist.experience} Years Experience
                  </p>
                </div>
                <div className="mb-2 mt-6 text-tertiary xl:my-3">
                  <p className="my-1 text-base font-medium text-light xl:text-[15px] ">
                    Bio:
                  </p>
                  <p className="text-[15px] xl:text-sm">
                    {selectedTherapist.bio}
                  </p>
                </div>
                <div className="items-center gap-x-12 text-tertiary xl:flex">
                  <div className="expertise mb-2 mt-6 xl:my-3">
                    <p className="my-1 mb-2 text-base font-medium text-light xl:text-[15px]">
                      Expertise:
                    </p>
                    <ul className="flex flex-wrap gap-x-2 gap-y-3">
                      {selectedTherapist.expertise.map((exp, index) => (
                        <li
                          className="rounded-full bg-dark px-4 py-2 text-[15px] text-light xl:text-sm"
                          key={index}
                        >
                          {capitalizeFirstLetter(exp)}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="my-3">
                    <p className="my-1 text-base font-medium text-light xl:text-[15px]">
                      Certificates:
                    </p>
                    <div className="my-2 flex flex-wrap gap-x-2 gap-y-4">
                      {selectedTherapist.certificates.map(
                        (certificate, index) => (
                          <a
                            key={index}
                            href={`${REACT_APP_S3_URL}/uploads/psychologists/certificates/${certificate.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="rounded-full bg-dark px-4 py-2 text-[15px] text-light xl:text-sm "
                          >
                            {capitalizeFirstLetter(certificate.name)}
                          </a>
                        ),
                      )}
                    </div>
                  </div>
                  <div className="my-3">
                    <p className="my-1 text-base font-medium text-light xl:text-[15px]">
                      Languages:
                    </p>
                    <div className="my-2 flex flex-wrap gap-x-2 gap-y-4 text-[15px] xl:text-sm">
                      {selectedTherapist.languages.map((language, index) => (
                        <span
                          key={index}
                          className="rounded-full bg-dark px-4 py-2 text-light"
                        >
                          {capitalizeFirstLetter(language)}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="sessions mb-5">
                  <p className="my-2 text-base font-medium text-light xl:text-[15px]">
                    Sessions:
                  </p>
                  <ul className="grid-cols-3 gap-x-2 xl:grid">
                    {selectedTherapist.sessions.map((session, index) => (
                      <li
                        key={index}
                        className={`mb-2 flex cursor-pointer items-center justify-between rounded-md px-3 py-2 ${
                          sessionSelected === index
                            ? "bg-white-light text-primary"
                            : "bg-dark text-white"
                        }`}
                        onClick={() => setSessionSelected(index)}
                      >
                        <div className="flex h-20 items-center gap-x-1">
                          <input
                            type="radio"
                            name="session"
                            className="styled-radio mr-1"
                            id={`session-${index}`}
                            checked={sessionSelected === index}
                            onChange={() => setSessionSelected(index)}
                          />
                          <div>
                            <label
                              htmlFor={`session-${index}`}
                              className={`${sessionSelected === index ? "text-primary" : "text-light"} font-medium`}
                            >
                              {session.title}
                            </label>
                            <p
                              className={`${sessionSelected === index ? "text-primary" : "text-light"} mr-2 text-[13px]`}
                            >
                              {session.description}
                            </p>
                          </div>
                        </div>
                        <div className="text-right">
                          {session.pricing.discount > 0 ? (
                            <div className="flex items-center gap-x-2">
                              <p
                                className={`${sessionSelected === index ? "text-primary" : "text-light"} text-[13px] line-through`}
                              >
                                {country !== "IN"
                                  ? `$${session.pricing.US}`
                                  : `₹${session.pricing.IN}`}
                              </p>
                              <p
                                className={`${sessionSelected === index ? "text-primary" : "text-light"} text-[13px]`}
                              >
                                (-{session.pricing.discount}%)
                              </p>
                              <p
                                className={`${sessionSelected === index ? "text-primary" : "text-light"}`}
                              >
                                {country !== "IN"
                                  ? `$${checkoutAmt(session.pricing.US, session.pricing.discount)}`
                                  : `₹${checkoutAmt(session.pricing.IN, session.pricing.discount)}`}
                              </p>
                            </div>
                          ) : (
                            <p
                              className={`${sessionSelected === index ? "text-primary" : "text-light"} font-medium`}
                            >
                              {country !== "IN"
                                ? `$${session?.pricing.US}`
                                : `₹${session?.pricing.IN}`}
                            </p>
                          )}
                          {sessionSelected === index && (
                            <button
                              className="mt-3 rounded-md bg-dark px-3 py-2 text-[15px] text-light xl:text-sm"
                              onClick={() =>
                                handleBuyNow({
                                  checkoutType: session.type,
                                  couponCode: "",
                                  productId: session._id,
                                  email: email || "support@onelifehealth.care",
                                  sessionType: session.title,
                                })
                              }
                            >
                              Buy Now
                            </button>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TherapistSidebar;
