import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const { REACT_APP_S3_URL } = process.env;

const BeingSensitive = () => {
  return (
    <>
      <Navbar />
      <section
        id="blogs"
        className="h-auto min-h-screen w-full bg-white bg-opacity-70 px-8 pb-6 pt-24 font-heading lg:px-24 lg:pb-20 lg:pt-36"
      >
        <img
          src={`${REACT_APP_S3_URL}/assets/Being-too-sensitive-is-it-a-bad-thing.jpg`}
          alt="Building-Self-Confidence-Ways-to-Improve-Low-Self-Confidence"
          className="w-full"
        />
        <h1 className="mb-4 mt-8 text-left text-2xl font-medium leading-snug sm:text-3xl lg:text-5xl lg:leading-tight">
          Being too senitive – is it a bad thing?
        </h1>
        <p className="font-bold sm:text-xl">By Naseem Kachwala</p>
        <p className="font-medium sm:text-lg">March 2, 2023</p>
        <div className="my-5 text-lg lg:my-10 lg:text-xl">
          <p className="my-3 font-semibold">
            Have you heard – “You are being too sensitive”?
          </p>
          <p className="my-3 font-semibold">
            Snap out of it. You are overthinking.
          </p>
          <p className="my-3 font-semibold">
            Like really? Did you let that get to you? Or affect you so much?”
          </p>
          <p className="my-3 font-semibold">
            And you wondered if you really are giving too much attention or
            importance to it.
          </p>
          <p className="my-3">
            A sensitive person is more likely to feel things deeply, whether
            those things are positive or negative. While the highs can be
            joyous, the lows can present challenges that can affect stress
            levels , relationships, and the possible ability to cope.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            WHY AM I SO SENSITIVE? I SHOULD BE LESS SENSITIVE.
          </h2>
          <p>
            Feeling heightened emotions or being over-sensitive can come down to
            diet choices, genetics, stress, anxiety , environment, and early
            childhood experiences. It can also be due to a chronic underlying
            health condition, such as a mood disorder or hormones.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            SO WHAT ARE THE COMMON REASONS BEHIND BEING OVER-SENSITIVE?
          </h2>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            1. EMOTIONS AREN’T WEAK, THEY ARE HUMAN
          </h2>
          <p>
            Everyone processes events and emotions differently. A person may
            feel things a bit more than others; or, might just be feeling extra
            sensitive on a particular day.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            2. GENETICS
          </h2>
          <p className="my-3">
            Research suggests that about 20% to 60% of a person’s temperament
            can be determined by genetics.
          </p>
          <p className="my-3">
            There is a personality trait called “sensory processing
            sensitivity”. It is a quality where someone processes the world more
            deeply. It includes the moods and feelings of others, as well as
            pain and loud noises.
          </p>
          <p className="my-3">
            In a study conducted in 2021 when comparing the levels of
            sensitivity in sets of adolescent twins, researchers found that 47%
            of the differences in sensitivity between the twins were genetic.
          </p>
          <p className="my-3">
            This supports the idea that people can inherit emotional
            sensitivity. Also is responsible in cases where siblings have
            different temperaments. It is suggested that this difference can
            also partly be due to DNA.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            3. SUSTAINED LACK OF SLEEP
          </h2>
          <p className="my-3">
            A prolonged lack of sleep has several negative effects on the body
            and mind. Being overly reactive is one of them.
          </p>
          <p className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            NOW THAT WE UNDERSTAND THE SIGNIFICANCE OF SELF-CONFIDENCE IN MENTAL
            HEALTH CARE. LET'S EXPLORE PRACTICAL STEPS ON HOW TO BUILD AND
            IMPROVE SELF-CONFIDENCE.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            4. LACK OF EXERCISE
          </h2>
          <p className="my-3">
            A study conducted in 2018 showed that regular physical exercise can
            contribute to better emotional regulation during stressful times.
          </p>
          <p className="my-3">
            Another study conducted in 2017 also showed that aerobic exercise
            had a therapeutic effect on regulating emotions.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            5. SUSTAINED UNHEALTHY DIET
          </h2>
          <p className="my-3">
            It is good to ensure having vital vitamins and minerals. Research
            done on this has found that eating an unhealthy diet can negatively
            influence mood.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            SITUATIONAL REASONS
          </h2>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            1. STRESS
          </h2>
          <p className="my-3">
            A study conducted in 2018 showed that living with high-stress levels
            can increase negative emotions such as anger or depression. In
            particular, the study showed that the more stress a person feels,
            the more the person tends to keep thinking about it what’s making
            them stressed, which in turn increases the person’s negative
            emotions.
          </p>{" "}
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            2. THERE IS A DIFFERENCE BETWEEN STRESS AND ANXIETY
          </h2>
          <p className="my-3">
            Stress usually responds to something specific in life and will go
            away. It is known to be Anxiety when a person experiences prolonged
            worry for no obvious external reason. For chronic anxiety, it can
            become an anxiety disorder, which can significantly impact emotional
            regulation.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            3. GRIEF
          </h2>
          <p className="my-3">
            Grieving is not only about losing a loved one . Grief can be for a
            past self, a child that was never born, or even a breakup. We all
            handle grief differently and we may need help to process that grief.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            4. HORMONE IMBALANCE
          </h2>
          <p className="my-3">
            Any hormonal imbalance can cause extra sensitivity.
          </p>{" "}
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            WHAT CAN YOU DO TO CONTROL YOUR SENSITIVITY NATURE?
          </h2>
          <p>
            Some sources of being over-sensitive can be healed by making basic
            lifestyle changes
          </p>
          <ul className="list-decimal">
            <li className="ml-4">
              Ensure having a consistent routine every day
            </li>
            <li className="ml-4">
              Doing away with the use of substances and alcohol
            </li>
            <li className="ml-4">Getting adequate sleep</li>
            <li className="ml-4">
              A healthy lifestyle, nutritious food, and taking time to do
              activities that bring joy.
            </li>
            <li className="ml-4">
              Speaking with and spending time with supportive family or
              friend/s.
            </li>
          </ul>
          <p className="my-3 font-semibold">
            However, if feelings of over-sensitivity are having a sustained
            negative impact on life, it is best to not let them go unnoticed. In
            India, mental help care is available.
          </p>
          <p className="my-3">
            Visiting a counselor who is medically trained to see if it is a
            short-term underlying cause or a chronic one. They can give an
            impartial point of view and help get to the root of the issue. If
            required, also refer to a specialist. Counselors are also bound by
            confidentiality as a necessary professional must and no one else
            would know what is discussed.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            BEING HIGHLY SENSITIVE IS ALSO CONSIDERED TO HAVE ITS ADVANTAGES.
            SOME OF THEM ARE:
          </h2>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            1. BEING SENSITIVE IS CONSIDERED A GOOD CHARACTER
          </h2>
          <p className="my-3">
            Highly sensitive people are known to be highly observant, intuitive,
            thoughtful, compassionate , empathetic, diligent, loyal, and
            creative.
          </p>
          <p className="my-3">
            In fact, managers consistently rate people with higher sensitivity
            as their top contributors.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            2. YES, A PERSON CAN BE SENSITIVE AND STRONG AT THE SAME TIME.
          </h2>
          <p>
            Very often cultural sensitivity is associated with weakness - but it
            shouldn't be. In fact, with the right mindset, a person can actually
            learn how to find strength in sensitivity.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            3. IN PERSONAL LIFE, SENSITIVE PEOPLE ARE SUPPORTIVE FRIENDS AND
            LOVED ONES.
          </h2>
          <p>
            They care deeply about their friends and tend to form deep bonds
            with people.
          </p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            4. SENSITIVE PEOPLE ARE NOTICED TO BE VERY GRATEFUL FOR THE LIFE
            THEY HAVE.
          </h2>
          <p>They are believed to be overall happy and content.</p>
          <h2 className="mb-2 mt-6 text-xl font-medium lg:text-sub-heading lg:font-semibold">
            5. OVERLY EXPRESSIVE
          </h2>
          <p>
            They appreciate a good meal, a beautiful song, or a small gesture.
            They express it with tears, a hug, and the rare emoji too. That
            leaves the receiving person with a fuzzy feeling of happiness.
          </p>
          <p className="my-3">
            There is a beautiful Rainbow for people who perceive being
            over-sensitive as a negative and are looking for how to not be
            sensitive. Really!!
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BeingSensitive;
