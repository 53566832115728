import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const skeletonBaseColor = "#242424";
const skeletonHighlightColor = "#3c3c3c";

const TherapistCardSkeleton: React.FC = () => {
  return (
    <div className="my-3 h-auto w-full rounded-xl border border-primary bg-secondary p-3 shadow-sm">
      <div className="flex items-start">
        <Skeleton
          circle={true}
          height={56}
          width={56}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          className="mr-3"
        />
        <div className="w-full">
          <div className="flex w-full items-center justify-between">
            <Skeleton
              height={16}
              width={100}
              baseColor={skeletonBaseColor}
              highlightColor={skeletonHighlightColor}
              className="mr-3"
            />
            <Skeleton
              height={16}
              width={20}
              baseColor={skeletonBaseColor}
              highlightColor={skeletonHighlightColor}
            />
          </div>
          <Skeleton
            height={12}
            width={140}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            height={12}
            width={100}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between">
        <Skeleton
          height={24}
          width={60}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          className="rounded-md"
        />
        <Skeleton
          height={24}
          width={80}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          className="rounded-md"
        />
      </div>
    </div>
  );
};

export default TherapistCardSkeleton;
